import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button, Pagination, Paper, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import userApi from '../../api/user.api';
import { HeaderBox, HeaderBoxWithButton } from '../../components/common/HeaderBox';
import { ClearIcon } from '../../components/common/Icon';
import MPaper from '../../components/common/MPaper';
import StackCol from '../../components/common/StackCol';
import StackRow from '../../components/common/StackRow';
import { routes, routesGen } from '../../configs/routes.config';
import LANGUAGE from '../../utils/language.util';


const UserPage = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState({ page: 1 });
  const [pageCount, setPageCount] = useState(1);
  const [getParamSuccess, setGetParamSuccess] = useState(false);
  const [partnerSearch, setPartnerSearch] = useState("");
  const [nameSearch, setNameSearch] = useState("");
  const [emailSearch, setEmailSearch] = useState("");

  useEffect(() => {
    getParams();
  }, []);

  useEffect(() => {
    const getListUsers = async () => {
      const { response, err } = await userApi.list(query);
      if (response) {
        setUsers(response.users);
        setPageCount(Math.ceil(response.count / 10));
      }
      if (err) toast.error(err.message);
    };
    if (getParamSuccess) {
      getListUsers();
    }
  }, [dispatch, query, getParamSuccess]);

  const getRole = (roleNumber) => {
    switch (roleNumber) {
      case 1:
        return LANGUAGE.ADMIN;
      case 2:
        return LANGUAGE.MANAGER;
      case 3:
        return LANGUAGE.USER;
      default:
        return "Unknown";
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
    setQuery({ page: value });
    addParams(value);
  };

  const addParams = (page) => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("page", page);
    window.history.pushState({ path: currentUrl.href }, "", currentUrl.href);
  };

  const getParams = () => {
    const url = new URL(window.location.href);
    const pageParam = url.searchParams.get("page");
    if (pageParam !== null) {
      const page = parseInt(pageParam, 10);
      setPage(page);
      setQuery({ page: page });
    }
    setGetParamSuccess(true);
  };

  const onPartnerSearchChange = (event) => {
    setPartnerSearch(event.target.value);
  };

  const onNameSearchChange = (event) => {
    setNameSearch(event.target.value);
  };

  const onEmailSearchChange = (event) => {
    setEmailSearch(event.target.value);
  };

  const onClickSearch = () => {
    setQuery({
      ...query,
      partner: partnerSearch,
      name: nameSearch,
      email: emailSearch
    });
  };

  return (
    <StackCol>
      {(user && user.user_role === 1) ?
        <HeaderBoxWithButton
          title={LANGUAGE.USER_MANAGEMENT}
          titleButton={LANGUAGE.USER_ADD}
          to={routes.USER_REGISTER}
        /> :
        <HeaderBox title={LANGUAGE.USER_MANAGEMENT} />
      }
      <MPaper sx={{ overflowX: 'auto', display: "flex" }}>
        <StackRow alignItems={"center"}>
          <TextField
            id="partner"
            label={LANGUAGE.PARTNER}
            name="partner"
            type="text"
            value={partnerSearch}
            onChange={onPartnerSearchChange}
            sx={{ minWidth: "100px", width: "30%" }}
          />
          <ClearIcon />
          <TextField
            id="name"
            label={LANGUAGE.FIRST_NAME}
            name="name"
            type="text"
            value={nameSearch}
            onChange={onNameSearchChange}
            sx={{ minWidth: "100px", width: "30%" }}
          />
          <ClearIcon />
          <TextField
            id="email"
            label={LANGUAGE.EMAIL}
            name="email"
            type="text"
            value={emailSearch}
            onChange={onEmailSearchChange}
            sx={{ minWidth: "100px", width: "30%" }}
          />
          <LoadingButton
            component="label"
            variant="contained"
            size="large"
            onClick={() => onClickSearch()}
            sx={{ minWidth: "80px" }}
          >
            {LANGUAGE.SEARCH}
          </LoadingButton>
        </StackRow>
      </MPaper>
      <Box pl={2}>
        <Typography>
          {LANGUAGE.USER_LIST}
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>{LANGUAGE.PERSON_NAME}</TableCell>
              <TableCell>{LANGUAGE.PREFECTURE}</TableCell>
              <TableCell>{LANGUAGE.EMAIL}</TableCell>
              <TableCell>{LANGUAGE.ROLE}</TableCell>
              <TableCell sx={{ width: 100 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users && users.map((user, index) => (
              <TableRow key={index}>
                <TableCell>{user.display_name}</TableCell>
                <TableCell>{user.prefecture}</TableCell>
                <TableCell>{user.user_email}</TableCell>
                <TableCell>{getRole(user.user_role)}</TableCell>
                <TableCell>
                  <Button
                    color="primary"
                    component={Link}
                    to={routesGen.userDetail(user.user_id)}
                  >
                    {LANGUAGE.DETAIL}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <StackRow spacing={2}>
        <Pagination
          variant="outlined"
          shape="rounded"
          color="primary"
          count={pageCount}
          page={page}
          onChange={handleChange}
        />
      </StackRow>
    </StackCol>
  );
};

export default UserPage;