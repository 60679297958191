export const APP_STATE = {
  DASHBOARD: "DASHBOARD",
  USER: "USER",
  MATERIAL: "MATERIAL",
  PROMPT: "PROMPT",
  PROFILE: "PROFILE",
  PARTNER: "PARTNER",
  SETTING: "SETTING",
  PASSWORD: "PASSWORD",
  SATELLITE: "SATELLITE",
};

export const ROLES = {
  ADMIN: "ADMIN",
  MANAGER: ["MANAGER", "ADMIN"],
  USER: "USER",
  ALL: "ALL"
};