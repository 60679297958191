import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const NotPermission = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Typography variant="h1" align="center" gutterBottom>
        403 - Forbidden
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        You do not have permission to access this page.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/"
        sx={{ marginTop: 2 }}
      >
        Go to Home
      </Button>
    </Container>
  );
};

export default NotPermission;
