import React from 'react';
import { Rect, Image } from '@react-pdf/renderer';

const CertificateImage = (props) => {
  const { imageBuffer, imageExt } = props;
  return (
    <Rect
      x="92"
      y="50"
      width="350"
      height="350"
      fill="none"
      stroke-miterlimit="10"
      stroke="#056fb8">
      <Image
        src={`data:image/${imageExt};base64,${imageBuffer}`}
        style={{
          x: "92",
          y: "50",
          width: "350",
          height: "350",
        }}
      />
    </Rect>
  );
};

export default CertificateImage;