import { AppBar, Box, Stack, Toolbar, useScrollTrigger } from "@mui/material";
import { cloneElement } from "react";
import { useSelector } from "react-redux";
import HideMenu from "./HideMenu";
import UserMenu from "./UserMenu";

const ScrollAppBar = ({ children, window }) => {

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
    target: window ? window() : undefined
  });

  return cloneElement(children, {
    sx: {
      color: trigger ? "text.primary" : "primary.contrastText",
      backgroundColor: trigger ? "background.paper" : "transparent"
    }
  });
};

const Topbar = () => {
  const { user } = useSelector((state) => state.user);

  return (
    <>
      <ScrollAppBar>
        <AppBar position="fixed" elevation={0} >
          <Toolbar sx={{
            display: "flex",
            justifyContent: 'space-between',
            alignItems: "center",
            width: "100%",
            backgroundColor: "white"
          }}>
            <Stack spacing={1}>
              {user && <HideMenu />}
            </Stack>
            <Box>
              {user && <UserMenu />}
            </Box>
          </Toolbar>
        </AppBar>
      </ScrollAppBar >
    </>
  );
};

export default Topbar;