import { Button, Paper, Stack, Table, TableBody, TableContainer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';

import userApi from '../../api/user.api';
import ButtonBox from '../../components/common/ButtonBox';
import { HeaderBox } from '../../components/common/HeaderBox';
import StackCol from '../../components/common/StackCol';
import StackRow from '../../components/common/StackRow';
import { TableRowTextAndButton, TableRowTwoText } from '../../components/common/TableRowCustom';
import { routes, routesGen } from '../../configs/routes.config';
import { addBreadcrumb, removeBreadcrumbAtLevel } from '../../redux/features/app.state.slice';
import { formattedDate } from '../../utils/date.utils';
import LANGUAGE from '../../utils/language.util';
import AlertErrorDialog from '../../components/dialog/AlertErrorDialog';


const UserDetailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const { id } = useParams();
  const [userDetail, setUser] = useState();
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessageDeleted, setErrorMessageDeleted] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      const { response, err } = await userApi.getOne(id);
      setUser(response);

      if (err) {
        toast.error(err.message);
        if (err.status === 404 || err.message === "user_id is invalid") {
          navigate(-1);
        }
      };
      if (response) setUser(response);
    };
    getUser();
  }, [id, navigate]);

  useEffect(() => {
    dispatch(addBreadcrumb({
      displayText: id,
      level: 3,
      link: routesGen.userDetail(id)
    }));
  }, [dispatch, id]);


  useEffect(() => {
    return () => {
      dispatch(removeBreadcrumbAtLevel({ level: 3 }));
    };
  }, [dispatch]);

  const onClickReturn = async () => {
    navigate(-1);
  };

  const onClickEdit = async () => {
    navigate(routesGen.userUpdate(id));
  };

  const getRole = (roleNumber) => {
    switch (roleNumber) {
      case 1:
        return LANGUAGE.ADMIN;
      case 2:
        return LANGUAGE.MANAGER;
      case 3:
        return LANGUAGE.USER;
      default:
        return "Unknown";
    }
  };

  const onClickConfirmDelete = async () => {
    setIsDeleting(true);
    const { response, err } = await userApi.delete(id);

    if (err) {
      setOpenErrorDialog(true);
      setErrorMessageDeleted(err.message);
      setIsDeleting(false);
    };
    if (response) {
      toast.success(LANGUAGE.DELETED_SUCCESS);
      setTimeout(() => {
        setIsDeleting(false);
        navigate(routes.USERS);
      }, 1500);
    };
  };

  return (
    <StackCol>
      <HeaderBox title={LANGUAGE.USER_MANAGEMENT} />
      {userDetail &&
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRowTwoText
                title={LANGUAGE.PERSON_NAME}
                value={userDetail.display_name}
              />
              <TableRowTwoText
                title={LANGUAGE.POST_CODE}
                value={userDetail.postcode}
              />
              <TableRowTwoText
                title={LANGUAGE.PREFECTURE}
                value={userDetail.prefecture}
              />
              <TableRowTwoText
                title={LANGUAGE.ADDRESS_PREFECTURE_BELOW}
                value={userDetail.address}
              />
              <TableRowTwoText
                title={LANGUAGE.BUILDING_NAME}
                value={userDetail.building}
              />
              <TableRowTwoText
                title={LANGUAGE.TELEPHONE}
                value={userDetail.tel}
              />
              <TableRowTwoText
                title={LANGUAGE.EMAIL}
                value={userDetail.user_email}
              />
              <TableRowTwoText
                title={LANGUAGE.ROLE}
                value={getRole(userDetail.user_role)}
              />
              <TableRowTwoText
                title={LANGUAGE.CREATED_DATE}
                value={formattedDate(userDetail.created_date)}
              />
              <TableRowTwoText
                title={LANGUAGE.UPDATED_DATE}
                value={formattedDate(userDetail.updated_date)}
              />
              <TableRowTextAndButton
                title={LANGUAGE.PARTNER}
                value={userDetail.partner?.company_name}
                to={(user && user.user_role === 1) ? routesGen.partnerDetail(userDetail.partner?.partner_id) : null}
              />
            </TableBody>
          </Table>
        </TableContainer>
      }
      {(user && user.user_role === 1) ?
        <ButtonBox
          onClickReturn={onClickReturn}
          onClickEdit={onClickEdit}
          onClickConfirmDelete={onClickConfirmDelete}
          isDeleting={isDeleting}
        /> :
        <StackRow>
          <Stack sx={{ width: "30%", alignItems: "end" }}>
          </Stack>
          <Stack sx={{ width: "70%" }}>
            <Button
              component={Paper}
              sx={{
                width: "100px",
                color: "black"
              }}
              onClick={onClickReturn}
            >
              {LANGUAGE.RETURN}
            </Button>
          </Stack>
        </StackRow>
      }
      <AlertErrorDialog
        openDialog={openErrorDialog}
        setOpenDialog={setOpenErrorDialog}
        title="ユーザーの削除"
        message={errorMessageDeleted}
      />
    </StackCol>
  );
};

export default UserDetailPage;