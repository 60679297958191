const LANGUAGE = {
  FIELD_REQUIRED: (field) => `[${field}]が必須です`,
  FIELD_INVALID: (field) => `[${field}]が無効です`,
  EMAIL: "メールアドレス",
  PASSWORD: "パスワード",
  PASSWORD_CONFIRM: "パスワード確認用",
  NICKNAME: "ニックネーム",
  ROLE: "権限",
  ADMIN: "アドミン",
  USER: "ユーザー",
  USER_ID: "ユーザーID",
  REGISTER: "登録する",
  REGISTERED: "登録しました",
  CANCEL: "キャンセル",
  EDIT: "編集",
  DELETE: "削除",
  DELETED: "削除しました",
  CREATED_DATE: "作成日時",
  UPDATED_DATE: "更新日時",
  STATUS: "ステータス",
  UPDATE: "更新する",
  UPDATED: "更新しました",
  YES: "有",
  NO: "無",
  PASSWORD_FORGOT: "パスワードを忘れた方はこちらへ",
  LOGIN: "ログイン",
  LOGIN_SUCCESS: "ログインが成功しました",
  PROMPT_CONTENT: "プロンプトの内容",
  MATERIAL_NAME: "資料名前",
  VALID: "有効",
  INVALID: "無効",
  DETAIL: "詳細へ",
  SEND: "送信",
  PERSON_NAME: "担当者名（姓名）",
  DOWNLOAD: "ダウンロードすろ",
  SEARCH: "検索",
  DELETED_SUCCESS: "正常に削除できました",
  OK: "OK",
  GOT_IT: "承知しました",
  SELECT: "選択",
  /* Partner */
  PARTNER_MANAGEMENT: "代理店管理",
  PARTNER_ADD: "代理店の登録",
  PARTNER: "代理店",
  SHORT_ID: "代理店ID",
  COMPANY_NAME: "会社名",
  FIRST_NAME: "名前",
  LAST_NAME: "姓",
  NAME_ONLY: "名",
  REPRESENTATIVE_NAME: "代表者名",
  POST_CODE: "郵便番号",
  COMPANY_POST_CODE: "会社郵便番号",
  PREFECTURE: "都道府県",
  ADDRESS_PREFECTURE_BELOW: "住所(都道府県以下)",
  BUILDING_NAME: "建物名",
  TELEPHONE: "電話番号",
  REPRESENTATIVE_TELEPHONE: "代表者電話番号",
  REPRESENTATIVE_EMAIL: "代表者メールアドレス",
  RETURN: "戻る",
  CONTACT_PERSON_NAME: "窓口担当名",
  CONTACT_PERSON_TEL: "窓口担当電話番号",
  CONTACT_PERSON_EMAIL: "窓口担当メールアドレス",
  BANK_NUMBER: "振込先口座番号",
  /* User */
  MANAGER: "管理者",
  USER_ADD: "ユーザーの登録",
  USER_MANAGEMENT: "ユーザー管理",
  USER_INFORMATION: "ユーザー情報",
  CUSTOMER_INFORMATION: "お客様情報",
  USER_LIST: "ユーザー一覧",
  NAME: "名前",
  PASSWORD_CHANGE: "パスワード変更",
  PASSWORD_OLD: "古いパスワード",
  PASSWORD_CHANGE_SUCCESS: "パスワード変更成功",
  /* Material */
  MATERIAL_MANAGEMENT: "マテリアル管理",
  MATERIAL_ADD: "情報の登録",
  NFT_ID: "NFTトークンID",
  NFT_TOKEN: "NFTトークン",
  TRACE_JSON_URL: "Trace Json URL",
  NFT_ISSUE_DATE: "NFT発行日",
  DOWNLOAD_CERTIFICATE: "証明書をダウンロードする",
  MOVE_TO_TRASH: "ゴミ箱へ移動する",
  REQUEST_NFT_ISSUANCE: "NFTの発行を依頼する",
  PHOTO: "写真",
  IMAGE_UPLOAD: "画像アップロード",
  TRACE_IMAGE_UPLOAD: "画像のアップロード",
  TRACE_JSON_UPLOAD: "JSON アップロード",
  DOWNLOAD_IMAGE: "画像をダウンロードする",
  DOWNLOAD_DATA: "データをダウンロードする",
  DESIGN_TYPE: "デザインタイプ",
  DESIRED_NFT_ISSUANCE_DATE: "NFT発行希望日",
  /* Password */
  PASSWORD_RESET: "パスワード再設定",
  PLEASE_ENTER_YOUR_EMAIL: "アカウントのメールアドレスを入力してください。",
  PASSWORD_FORGOT_SUCCESS: "パスワードリセット成功",
  PASSWORD_FORGOT_NOTIFICATION: "● パスワードリセットの手順を記載したメールがお使いの受信トレイに送信されました。メールをご確認ください。",
  PASSWORD_NEW: "新しいパスワード",
  PASSWORD_NEW_CONFIRM: "新しいパスワードの確認用",
  PASSWORD_MUST_MATCH: "パスワードが一致する必要があります",
  PASSWORD_RESET_SUCCESS: "パスワードリセットが成功しました",
  PASSWORD_RESET_NOTIFICATION: "● パスワードのリセットが成功しました。新しいパスワードを使用して再度サインインしてください。",
  /* SignUp */
  SIGN_UP_TEXT: "アカウント登録はこちらへ",
  SIGN_UP_SUCCESS: "● 登録が成功しました。登録したアカウントでログインしてください。",
  TERMS_OF_USE: "利用規約",
  PRIVACY_POLICY: "個人情報保護方針",
  AGREE: "同意する",
  /* Certificate */
  CERTIFICATE: "供養証明書",
  CERTIFICATE_ADD: "供養証明書の登録",
  TARGET_PERSON_NAME: "対象者名",
  SEND_DATE: "送信日",
  CERTIFICATE_ISSUE_DATE: "証明書発行日",
  DELETE_ITEM: "項目削除",
  CERTIFICATE_ADD_IMAGE: "供養証明書の画像項目を追加",
  PLEASE_FOLLOW_ME_ON_SKY: "これからも空から見守っててね。",
  MESSAGE: "メッセージ",
  IMAGE: "画像",
  APPLICANT_NAME: "申込者名",
  CERTIFICATE_ISSUE: "証明書を発行する",
  CERTIFICATE_MAKE_SUCCESS: "証明書を成功させる",
  PLEASE_UPLOAD_SQUARE_IMAGE: "※ 正方形画像をアップロードしてください",
  DESIRED_SEND_DATE: "送信希望日",
  CERTIFICATE_CHECK: "証明書を確認する",
  SAT_NAME: "衛星名",
  SAT_SHOW: "衛星を見る",
  // 
  MANAGEMENT_SCREEN: "管理画面",
  REGISTRATION_INFORMATION: "ご登録情報",
  REGISTRATION_INFORMATION_LIST: "登録情報一覧",
  PHOTO_OF_THE_DECEASED: "遺影写真画像",
  CAPSULES_OPEN_DATE: "そらカプセル開封希望日",
  CAPSULES_MESSAGE: "そらカプセルメッセージ",
  CAPSULES_VIDEO: "そらカプセルビデオ",
  // 
  SORA_MEMORIAL_CERTIFICATE: "そらメモリアル証明書",
  WHERE_IS_NOW: "イマドコ？",
  REGISTRATION_PERSON: "ご登録者様",
  REGISTRATION_MESSAGE: "登録メッセージ",
  DESTINATION_SATELLITE: "送信先衛星",
  CONTRACTOR: "ご契約者様",
  REGISTERED_IMAGE: "ご登録画像",
  PDF_DOWNLOAD: "PDF でダウンロード",
  TODAY_SKY_MEMO_TIME: "今日のそらメモタイム",
  // 
  REGISTERED_LOCATION: "ご登録地点",
  MONTHLY_MEMORIAL_DATE: "ご命日",
  EMAIL_NOTIFICATION_1: "通知メール1",
  EMAIL_NOTIFICATION_2: "通知メール2",
  EMAIL_NOTIFICATION_3: "通知メール3",
  EMAIL_NOTIFICATION_4: "通知メール4",
};

export default LANGUAGE;