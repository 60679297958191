import React from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { VisibilityIcon, VisibilityOffIcon } from '../common/Icon';

const TextFieldPassword = (props) => {
  let inputProps = {};
  if (!props.autoComplete) {
    inputProps = {
      autoComplete: 'new-password',
      form: {
        autoComplete: 'off',
      },
    };
  }

  return (
    <TextField
      color="success"
      fullWidth
      sx={props.sx}

      // Value
      id={props.id}
      label={props.label}
      placeholder={props.placeholder}
      type={props.showPassword ? 'text' : 'password'}
      name={props.name}
      value={props.form.values[props.id]}
      onChange={props.form.handleChange}
      error={props.form.touched[props.id] && props.form.errors[props.id] !== undefined}
      helperText={props.form.touched[props.id] && props.form.errors[props.id]}

      // Others
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={props.toggleVisibility}>
              {props.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputProps={inputProps}
    />
  );
};

export {
  TextFieldPassword
};