import { LoadingButton } from "@mui/lab";
import {
  Box, Typography
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import authApi from '../../api/auth.api';
import StackCol from '../../components/common/StackCol';
import { TextFieldCustom } from "../../components/common/TextFieldCustom";
import LANGUAGE from "../../utils/language.util";

const PasswordForgotPage = () => {
  const [isForgotRequest, setIsForgotRequest] = useState(false);
  const [isSendMail, setIsSendMail] = useState(false);

  const formForgotPass = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.EMAIL))
        .email(LANGUAGE.FIELD_INVALID(LANGUAGE.EMAIL))
    }),
    onSubmit: async values => {
      setIsForgotRequest(true);
      setIsSendMail(false);
      const params = {
        user_email: values.email
      };

      const { response, err } = await authApi.passwordForgot(params);
      if (response) {
        toast.success(LANGUAGE.PASSWORD_FORGOT_SUCCESS);
        setIsSendMail(true);
        setIsForgotRequest(false);
      }

      if (err) {
        setTimeout(() => {
          setIsForgotRequest(false);
          toast.error(err.message);
        }, 2000);
      }
    }
  });

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          pt: 20,
        }}>
        <Typography sx={{
          fontSize: 25
        }}>
          {LANGUAGE.PASSWORD_RESET}
        </Typography>
        <Typography sx={{
          pt: 2
        }}>
          {LANGUAGE.PLEASE_ENTER_YOUR_EMAIL}
        </Typography>
      </Box>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        "::-webkit-scrollbar": { display: "none" },
        pt: 5
      }}>
        <Box component="form" maxWidth={400} width="100%" onSubmit={formForgotPass.handleSubmit}>
          <StackCol spacing={3} alignItems='center'>
            <TextFieldCustom
              id={"email"}
              name={"email"}
              form={formForgotPass}
              label={LANGUAGE.EMAIL}
              sx={{
                width: { xl: "100%", lg: "80%", md: "80%", xs: "90%" }
              }}
            />
            <LoadingButton
              type="submit"
              size="large"
              variant="contained"
              loading={isForgotRequest}
              sx={{
                width: "200px"
              }}>
              {LANGUAGE.SEND}
            </LoadingButton>
          </StackCol>
        </Box>
      </Box>
      {isSendMail &&
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 4,
          }}>
          <Typography sx={{
            maxWidth: "380px",
            color: "green",
            align: "justify"
          }}>
            {LANGUAGE.PASSWORD_FORGOT_NOTIFICATION}
          </Typography>
        </Box>
      }
    </>
  );
};

export default PasswordForgotPage;