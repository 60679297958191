import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { setUser } from '../../redux/features/user.slice';
import { authUtils } from '../../utils/auth.utils';

const AppLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuth = async () => {
      const user = await authUtils.isAuthenticated();
      const pathName = window.location.pathname;
      if (user) {
        dispatch(setUser(user));
        if (['/signin', '/password/forgot', '/password/reset', '/signup'].some(p => pathName.startsWith(p))) {
          navigate('/');
        }
      } else if (!['/password/forgot', '/password/reset', '/signup'].some(p => pathName.startsWith(p))) {
        navigate('/signin');
      }
    };
    checkAuth();
  }, [dispatch, navigate]);


  return (
    <Outlet />
  );
};

export default AppLayout;