import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import materialApi from '../../api/material.api';
import LoadingPage from '../../components/common/LoadingPage';
import StackCol from '../../components/common/StackCol';
import StackRow from '../../components/common/StackRow';
import { BtnDownLoadColor } from '../../styles/custom.style';
import { formattedDate, getDetailTimeFromDate } from '../../utils/date.utils';
import LANGUAGE from '../../utils/language.util';
import CertificatePDF from '../Material/CertificatePDF';
import CertificatePage from './CertificatePage';

const MaterialCertificateUserPage = (props) => {
  const { material } = props;
  const [imageBuffer, setImageBuffer] = useState(null);
  const [imageExt, setImageExt] = useState("");
  const [currentTime, setCurrentTime] = useState(new Date());
  const [sentMessage, setSentMessage] = useState("");
  const [isRendering, setIsRendering] = useState(false);
  const [blob, setBlob] = useState(null);
  const [sizeImage, setSizeImage] = useState({
    hasSize: false,
    height: "100%",
    width: "100%"
  });
  
  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    const getImage = async (id) => {
      const response = await materialApi.getImageBuffer(id);
      if (response) {
        setImageBuffer(response);
      }
    };

    if (material) {
      if (material.material_image_link) {
        const getFileExtension = (url) => url.split('/').pop().split('.').pop();
        const fileExtension = getFileExtension(material.material_image_link);
        setImageExt(fileExtension);
        getImage(material.material_id);
      }

      if (material.trace_json_url) {
        const detailTime = getDetailTimeFromDate(material.updated_date);
        const sentMessage = `送信日時  ${detailTime.year} 年 ${detailTime.month} 月 ${detailTime.day} 日 ${detailTime.hour} 時 ${detailTime.minute} 分`;
        setSentMessage(sentMessage);
      }
    }
  }, [material]);



  const handleDownloadClick = async () => {
    setIsRendering(true);
    const dataMakePDF = {
      nickname: material.nickname,
      username: material.user.display_name,
      id: material.material_id,
      message: material.message,
      sentMessage: sentMessage,
      imageBuffer: imageBuffer,
      imageExt: imageExt,
      sizeImage: sizeImage
    };
    const blob = await pdf(
      <CertificatePDF data={dataMakePDF} />
    ).toBlob();
    setBlob(blob);
  };

  useEffect(() => {
    if (blob) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${material.material_id}.pdf`;
      link.click();
      setIsRendering(false);
    }
  }, [blob, material.material_id]);

  useEffect(() => {
    if (material.material_image_link) {
      const img = new Image();
      img.src = material.material_image_link;
      img.onload = () => {
        if (img.width > img.height) {
          setTimeout(() => {
            setSizeImage({
              hasSize: true,
              width: "60%",
              height: "auto"
            });
          }, 500);
        } else {
          setTimeout(() => {
            setSizeImage({
              hasSize: true,
              width: "auto",
              height: "65%"
            });
          }, 500);
        }
      };
    }
  }, [material]);

  return (
    ((!material || !sizeImage.hasSize) ? <LoadingPage /> :
      <StackCol
        alignItems="center"
        justifyContent="space-between"
        sx={{
          height: "100%"
        }}>
        <CertificatePage
          material={material}
          sentMessage={sentMessage}
          sizeImage={sizeImage}
        />
        <LoadingButton
          loading={isRendering}
          variant="contained"
          onClick={handleDownloadClick}
          sx={{
            width: "100%",
            maxWidth: "180px",
            bgcolor: BtnDownLoadColor,
            '&:hover': {
              bgcolor: BtnDownLoadColor,
            },
          }}
        >
          {LANGUAGE.PDF_DOWNLOAD}
        </LoadingButton>
        <StackRow justifyContent="center">
          <Typography sx={{
            fontSize: { lg: 20, md: 20, sm: 20, xs: 13 }
          }}>
            LOCAL TIME {formattedDate(currentTime)}
          </Typography>
        </StackRow>
      </StackCol>
    )
  );
};

export default MaterialCertificateUserPage;