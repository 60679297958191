import React from 'react';
import { Stack } from '@mui/material';

const StackCol = (props) => {
  return (
    <Stack
      spacing={props.spacing ? props.spacing : 2}
      direction="column"
      {...props}
    />
  );
};

export default StackCol;
