import { LoadingButton } from "@mui/lab";
import {
  Box, Typography
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import authApi from '../../api/auth.api';
import { HeaderBox } from '../../components/common/HeaderBox';
import MPaper from '../../components/common/MPaper';
import StackCol from '../../components/common/StackCol';
import { TextFieldPassword } from '../../components/password/PasswordComponents';
import { routes } from "../../configs/routes.config";
import LANGUAGE from "../../utils/language.util";

const PasswordChangePage = () => {
  const navigate = useNavigate();

  const [isChangeRequest, setIsChangeRequest] = useState(false);
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const togglePasswordOldVisibility = () => {
    setShowPasswordOld((prevShowPassword) => !prevShowPassword);
  };

  const togglePasswordNewVisibility = () => {
    setShowPasswordNew((prevShowPassword) => !prevShowPassword);
  };

  const togglePasswordConfirmVisibility = () => {
    setShowPasswordConfirm((prevShowPassword) => !prevShowPassword);
  };

  const form = useFormik({
    initialValues: {
      password: "",
      password_new: "",
      password_new_repeat: ""
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.PASSWORD_OLD)),
      password_new: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.PASSWORD_NEW)),
      password_new_repeat: Yup.string()
        .oneOf([Yup.ref('password_new'), null], LANGUAGE.PASSWORD_MUST_MATCH)
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.PASSWORD_NEW_CONFIRM)),
    }),
    onSubmit: async values => {
      setIsChangeRequest(true);
      const params = {
        old_password: values.password,
        new_password: values.password_new,
        new_password_repeat: values.password_new_repeat,
      };

      const { response, err } = await authApi.passwordChange(params);
      if (response) {
        toast.success(LANGUAGE.PASSWORD_CHANGE_SUCCESS);

        setTimeout(() => {
          setIsChangeRequest(false);
        }, 2100);

        setTimeout(() => {
          navigate(routes.HOME);
        }, 3000);
      }

      if (err) {
        setTimeout(() => {
          setIsChangeRequest(false);
          toast.error(err.message);
        }, 2000);
      }
    }
  });

  return (
    <>
      <HeaderBox title={LANGUAGE.PASSWORD_CHANGE} />
      <MPaper>
        <Typography sx={{
          display: "flex",
          justifyContent: "center",
          pt: 10,
          fontSize: 25
        }}>
          {LANGUAGE.PASSWORD_CHANGE}
        </Typography>
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          pt: 5
        }}>
          <Box component="form" maxWidth={400} width="100%" onSubmit={form.handleSubmit}>
            <StackCol spacing={3} alignItems='center'>
              <TextFieldPassword
                form={form}
                id={"password"}
                name={"password"}
                label={LANGUAGE.PASSWORD_OLD}
                showPassword={showPasswordOld}
                toggleVisibility={togglePasswordOldVisibility}
              />
              <TextFieldPassword
                form={form}
                id={"password_new"}
                name={"password_new"}
                label={LANGUAGE.PASSWORD_NEW}
                showPassword={showPasswordNew}
                toggleVisibility={togglePasswordNewVisibility}
                autoComplete={true}
              />
              <TextFieldPassword
                form={form}
                id={"password_new_repeat"}
                name={"password_new_repeat"}
                label={LANGUAGE.PASSWORD_NEW_CONFIRM}
                showPassword={showPasswordConfirm}
                toggleVisibility={togglePasswordConfirmVisibility}
                autoComplete={true}
              />
              <LoadingButton
                type="submit"
                size="large"
                variant="contained"
                loading={isChangeRequest}
                sx={{
                  width: "200px"
                }}>
                {LANGUAGE.SEND}
              </LoadingButton>
            </StackCol>
          </Box>
        </Box>
      </MPaper>
    </>
  );
};

export default PasswordChangePage;