import React from 'react';
import { Text, Font, View } from '@react-pdf/renderer';
import NotoSerifJPBold from "../../assets/fonts/NotoSerifJP/NotoSerifJP-Bold.otf";


const CertificateText = (props) => {
  Font.register({
    family: 'Noto Serif JP',
    fonts: [
      {
        src: NotoSerifJPBold,
        fontWeight: 'bold'
      }
    ]
  });

  return (
    <View style={{
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '440px',
    }}>
      <Text
        style={{
          fontFamily: "Noto Serif JP",
          fontSize: "35px",
          marginHorizontal: 8,
        }}>
        {props.data.nickname}
      </Text>
      <Text
        style={{
          fontFamily: "Noto Serif JP",
          fontSize: "20px",
          marginTop: "10px",
          fontWeight: "bold",
        }}>
        様
      </Text>
    </View>
  );
};

export default CertificateText;