import authApi from "../api/auth.api";

const authUtils = {
  isAuthenticated: async () => {
    const token = localStorage.getItem('access_token');
    if (!token) return false;
    try {
      const res = await authApi.verifyToken();
      return res.response;
    } catch {
      return false;
    }
  }
};

const roleUtils = {
  isAvailable: (roles, userRole) => {
    let userRoleText;

    switch (userRole) {
      case 1:
        userRoleText = "ADMIN";
        break;
      case 2:
        userRoleText = "MANAGER";
        break;
      case 3:
        userRoleText = "USER";
        break;
      default:
        userRoleText = "UNKNOWN";
        break;
    }

    if (roles === "ALL") {
      return true;
    }
    if (roles.includes(userRoleText)) {
      return true;
    }
    return false;
  }
};

export {
  authUtils,
  roleUtils
};