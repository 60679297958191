import { callAPI } from "./axios.client";

const authEndpoints = {
  register: "auth/register",
  signin: "auth/signin",
  signup: "auth/signup",
  passwordForgot: "auth/password/forgot",
  passwordReset: "auth/password/reset",
  passwordChange: "auth/password/change",
  verifyToken: "auth/verify-token"
};


const authApi = {
  signin: async (params) => await callAPI("post", authEndpoints.signin, params),
  signup: async (params) => await callAPI("post", authEndpoints.signup, params),
  verifyToken: async () => await callAPI("post", authEndpoints.verifyToken),
  register: async (params) => await callAPI("post", authEndpoints.register, params),
  passwordForgot: async (params) => await callAPI("post", authEndpoints.passwordForgot, params),
  passwordReset: async (params) => await callAPI("post", authEndpoints.passwordReset, params),
  passwordChange: async (params) => await callAPI("put", authEndpoints.passwordChange, params),
};

export default authApi;
