import { LoadingButton } from '@mui/lab';
import { Box, Button, TableCell } from '@mui/material';
import React from 'react';
import LANGUAGE from '../../utils/language.util';
import MPaper from '../common/MPaper';

const ButtonMaterialCustom = (props) => {
  return (
    <Button
      id={props.id}
      disabled={props.disabled}
      variant="contained"
      sx={{
        width: props.width || "200px",
        bgcolor: props.color,
        "&:hover": {
          bgcolor: props.color,
        },
      }}
      onClick={props.onClick}
    >
      {props.btnName}
    </Button>
  );
};

const ButtonMaterialLoadingCustom = (props) => {
  return (
    <LoadingButton
      id={props.id}
      disabled={props.disabled}
      variant="contained"
      sx={{
        width: props.width ? props.width : "200px",
        bgcolor: props.color,
        "&:hover": {
          bgcolor: props.color,
        },
      }}
      onClick={props.onClick}
      loading={props.loading}
    >
      {props.btnName}
    </LoadingButton>
  );
};

const MaterialPhoto = (props) => {
  return (
    <MPaper sx={{
      width: { xl: "40%", lg: "40%", md: "100%", xs: "100%" },
      pt: "15px !important"
    }}>


      <Box sx={{
        borderBottom: "1px solid #EEEEEE",
        pb: "10px"
      }}>
        {LANGUAGE.PHOTO_OF_THE_DECEASED}
      </Box>
      {props.material_image_link &&
        <Box sx={{
          p: 2
        }}>
          <img
            src={props.material_image_link}
            alt="material_photo"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }} />
        </Box>
      }
    </MPaper>
  );
};

const TableCellInfo = (props) => {
  const { borderBottomColor, width } = props;
  return (
    <TableCell
      align='center'
      width={width}
      sx={{
        padding: 0,
        height: { xl: 80, lg: 80, md: 80, sm: 80, xs: 60 },
        borderBottom: "none",
        position: 'relative',
        '&::after': {
          content: '""',
          display: 'block',
          width: '95%',
          margin: '0 auto',
          borderBottom: '1px solid black',
          position: 'absolute',
          bottom: 0,
          borderBottomColor: borderBottomColor
        }
      }}>
      {props.children}
    </TableCell>
  );
};


export {
  ButtonMaterialCustom, ButtonMaterialLoadingCustom, MaterialPhoto, TableCellInfo
};

