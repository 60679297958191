import { useNavigate } from 'react-router-dom';
import { IMAGES } from "../../assets";

const Logo = (props) => {
  const navigate = useNavigate();

  return (
    <img src={IMAGES.logo} alt='logo' height={props.height ? props.height : 60} style={{ cursor: 'pointer' }} onClick={() => navigate("/")} />
  );
};

export default Logo;