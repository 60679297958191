import { Box, Stack, Typography } from "@mui/material";
import { IMAGES } from "../../assets";
import StackCol from "../../components/common/StackCol";
import StackRow from "../../components/common/StackRow";

const CertificatePage = (props) => {
  const { material, sentMessage, sizeImage } = props;

  return (
    <StackCol
      sx={{
        backgroundColor: "white",
        mt: 2,
        alignItems: "center",
        height: { xl: "85%", lg: "85%", md: "85%", sm: "85%", xs: "85%" }
      }}>
      <StackCol sx={{
        alignItems: 'center',
        height: { lg: "100%", md: "100%", sm: "100%", xs: "100%" },
        width: { lg: 400, md: 400, sm: 400, xs: "20rem" },
        maxWidth: { lg: 400, md: 400, sm: 400, xs: "20rem" },
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${IMAGES.certificate_background})`,
        border: 1,
        justifyContent: "space-evenly",
      }}
        spacing={0}
      >
        <Box sx={{
          height: "15%",
          width: "100%",
          position: "relative",
          justifyContent: "center",
          display: "flex"
        }}>
          <img
            src={IMAGES.certificate_decor_with_text}
            alt="certificate_decor_with_text"
            style={{
              height: '100%',
            }}>
          </img>
        </Box>
        <StackCol sx={{
          alignItems: 'center',
          height: { xl: "65%", lg: "65%", md: "65%", sm: "65%", xs: "65%" },
          width: { lg: "100%", md: "100%", sm: "100%", xs: "100%" },
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${IMAGES.certificate_frame_with_bg})`,
          justifyContent: "space-around",
          padding: "4%"
        }}
        >
          <Box sx={{
            ...sizeImage,
            justifyContent: "center",
            display: "flex",
          }}>
            <img
              src={material.material_image_link} alt="material_img"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover"
              }}>
            </img>
          </Box>
          <StackRow spacing={1}
            alignItems="end"
            justifyContent="center"
            sx={{
              width: { lg: "60%", md: "60%", sm: "60%", xs: "60%" },
              maxWidth: { xs: "180px" },
              borderBottom: 1.5,
            }}>
            <Typography sx={{
              fontSize: { lg: 20, md: 20, sm: 20, xs: 15 },
              fontWeight: "bold"
            }}>
              {material.nickname}
            </Typography>
            <Typography sx={{
              fontSize: { lg: 18, md: 18, sm: 18, xs: 10 },
              fontWeight: "bold"
            }}>
              様
            </Typography>
          </StackRow>
          <Stack alignItems="center" sx={{
            width: "100%"
          }}>
            <Typography sx={{
              fontSize: "clamp(7px, 1.5vw, 10px)"
            }}>
              {sentMessage}
            </Typography>
            <Typography sx={{
              fontSize: "clamp(7px, 1.4vw, 10px)"
            }}>
              お預かりしたデータを空に送信したことを証明いたします。
            </Typography>
          </Stack>
        </StackCol>
        <Box sx={{
          width: { lg: "45%", md: "45%", sm: "45%", xs: "35%" }
        }}>
          <img src={IMAGES.certificate_footer_full} alt="footer" width="100%"></img>
        </Box>
      </StackCol>
    </StackCol>
  );
};

export default CertificatePage;