import { TabContext, TabPanel } from '@mui/lab';
import { Paper, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import materialApi from '../../api/material.api';
import { setMaterialTab } from '../../redux/features/app.state.slice';
import LANGUAGE from '../../utils/language.util';
import MaterialDetailUserPage from './MaterialDetailUserPage';
import SatelliteUserPage from './SatelliteUserPage';
import MaterialCertificateUserPage from './MaterialCertificateUserPage';

const MaterialUserPage = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const [material, setMaterial] = useState();
  const [tab, setTab] = useState('information');

  const handleChange = (_, value) => {
    setTab(value);
    addParams(value);
  };

  const addParams = (tab) => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("tab", tab);
    window.history.pushState({ path: currentUrl.href }, "", currentUrl.href);
    dispatch(setMaterialTab(tab));
  };

  useEffect(() => {
    const getMaterial = async () => {
      const { response, err } = await materialApi.getOne(id);

      if (err) toast.error(err.message);
      if (response) {
        setMaterial(response);
      };
    };
    getMaterial();
  }, [id]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const tabName = url.searchParams.get("tab");
    if (tabName !== null) {
      setTab(tabName);
      dispatch(setMaterialTab(tabName));
    } else {
      setTab("information");
      dispatch(setMaterialTab("information"));
    }
  }, [dispatch]);

  return (
    (material ?
      <TabContext value={tab}>
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="Material Tabs"
          variant="fullWidth"
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: 'yellow',
              height: "6px"
            },
          }}
        >
          <Tab
            label={LANGUAGE.REGISTRATION_INFORMATION}
            value="information"
            sx={{
              fontSize: { lg: 24, md: 20, sm: 14, xs: 13 },
              fontWeight: "medium",
              color: '#3f7ec1',
              bgcolor: "white",
              borderRadius: "10px 10px 0 0"
            }}
          />
          <Tab
            label={LANGUAGE.SORA_MEMORIAL_CERTIFICATE}
            value="certificate"
            sx={{
              fontSize: { lg: 24, md: 16, sm: 14, xs: 13 },
              fontWeight: "medium",
              color: '#3f7ec1',
              bgcolor: "white",
              ml: 1,
              borderRadius: "10px 10px 0 0",
            }}
          />
          <Tab
            label={LANGUAGE.WHERE_IS_NOW}
            value="satellite"
            sx={{
              fontSize: { lg: 24, md: 20, sm: 14, xs: 13 },
              fontWeight: "medium",
              color: '#3f7ec1',
              bgcolor: "white",
              ml: 1,
              borderRadius: "10px 10px 0 0"
            }}
          />
        </Tabs>
        <Paper
          sx={{
            width: "100%",
            minWidth: "350px",
            height: { xl: "650px", lg: "650px", md: "650px", sm: "650px", xs: "600px" },
            boxShadow: 5,
            position: 'relative',
            zIndex: 1,
            borderRadius: "0 0 10px 10px"
          }}>
          <TabPanel value="information" sx={{
            height: "100%",
            pb: 1
          }}>
            <MaterialDetailUserPage
              material={material}
            />
          </TabPanel>
          <TabPanel value="certificate" sx={{
            height: "100%",
            padding: 1
          }}>
            <MaterialCertificateUserPage
              material={material}
            />
          </TabPanel>
          <TabPanel value="satellite" sx={{
            padding: 1
          }}>
            <SatelliteUserPage />
          </TabPanel>
        </Paper>
      </TabContext>
      :
      <></>
    )
  );
};

export default MaterialUserPage;