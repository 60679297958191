import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import { router } from "./routes";
import "./styles/custom.css";

const App = () => {
  const theme = createTheme({
    typography: {
      fontFamily: [
        'Noto Serif JP',
      ].join(','),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {/* config toastify */}
      <ToastContainer
        position="bottom-left"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
      {/* mui reset css */}
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider >
  );
};

export default App;
