import { callAPI, callAPIGetFile, callAPIGetFileBuffer } from "./axios.client";


export const materialEndpoints = {
  list: "materials",
  create: "materials",
  getOne: ({ id }) => `materials/${id}`,
  delete: ({ id }) => `materials/${id}`,
  getImage: ({ id }) => `materials/${id}/image`,
  getImageBuffer: ({ id }) => `materials/${id}/image`,
  getJson: ({ id }) => `materials/${id}/json`,
  getCertificate: ({ id }) => `materials/${id}/certificate`,
};


const materialApi = {
  list: async (query) => await callAPI("get", `${materialEndpoints.list}?page=${query.page ? query.page : 0}`),
  create: async (params) => await callAPI("post", materialEndpoints.create, params),
  getOne: async (id) => await callAPI("get", materialEndpoints.getOne({ id })),
  delete: async (id) => await callAPI("delete", materialEndpoints.delete({ id })),
  getImage: async (id) => await callAPIGetFile("get", materialEndpoints.getImage({ id })),
  getImageBuffer: async (id) => await callAPIGetFileBuffer("get", materialEndpoints.getImageBuffer({ id })),
  getJson: async (id) => await callAPIGetFile("get", materialEndpoints.getJson({ id })),
  getCertificate: async (id) => await callAPIGetFile("get", materialEndpoints.getCertificate({ id })),
};

export default materialApi;
