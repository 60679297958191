import { Box, Table, TableBody, TableContainer, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StackCol from '../../components/common/StackCol';
import StackRow from '../../components/common/StackRow';
import { TableCellInfo } from '../../components/material/MaterialComponents';
import { MainColor1 } from '../../styles/custom.style';
import { formattedDate, formattedDateNotTime } from '../../utils/date.utils';
import LANGUAGE from '../../utils/language.util';

const MaterialDetailUserPage = (props) => {
  const { material } = props;

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  return (
    <StackCol justifyContent="space-between" sx={{ height: "100%" }}>
      <StackRow justifyContent="space-between">
        <TableContainer sx={{ width: "60%" }}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCellInfo width="30%">
                  <Typography
                    sx={{
                      fontSize: { xl: 25, lg: 20, md: 18, sm: 13, xs: 10 }
                    }}
                    fontWeight="bold"
                  >
                    {LANGUAGE.REGISTRATION_PERSON}
                  </Typography>
                </TableCellInfo>
                <TableCellInfo
                  width="70%"
                  borderBottomColor={MainColor1}
                >
                  <Typography sx={{
                    fontSize: { xl: 25, lg: 20, md: 18, sm: 13, xs: 10 }
                  }}>
                    {material.nickname}
                  </Typography>
                </TableCellInfo>
              </TableRow>
              <TableRow>
                <TableCellInfo width="30%">
                  <Typography
                    sx={{
                      fontSize: { xl: 25, lg: 20, md: 18, sm: 13, xs: 10 }
                    }}
                    fontWeight="bold"
                  >
                    {LANGUAGE.MONTHLY_MEMORIAL_DATE}
                  </Typography>
                </TableCellInfo>
                <TableCellInfo width="70%" borderBottomColor={MainColor1}>
                  <Typography sx={{
                    fontSize: { xl: 25, lg: 20, md: 18, sm: 13, xs: 10 }
                  }}>
                    {formattedDateNotTime(material.monthly_memorial_date)}
                  </Typography>
                </TableCellInfo>
              </TableRow>
              <TableRow >
                <TableCellInfo width="30%">
                  <Typography
                    sx={{
                      fontSize: { xl: 25, lg: 20, md: 18, sm: 13, xs: 10 }
                    }}
                    fontWeight="bold"
                  >
                    {LANGUAGE.MESSAGE}
                  </Typography>
                </TableCellInfo>
                <TableCellInfo width="70%" borderBottomColor={MainColor1}>
                  <Typography sx={{
                    fontSize: { xl: 25, lg: 20, md: 18, sm: 13, xs: 10 }
                  }}>
                    {material.message}
                  </Typography>
                </TableCellInfo>
              </TableRow>
              <TableRow >
                <TableCellInfo width="30%">
                  <Typography
                    sx={{
                      fontSize: { xl: 25, lg: 20, md: 18, sm: 13, xs: 10 }
                    }}
                    fontWeight="bold"
                  >
                    {LANGUAGE.SEND_DATE}
                  </Typography>
                </TableCellInfo>
                <TableCellInfo width="70%" borderBottomColor={MainColor1}>
                  <Typography sx={{
                    fontSize: { xl: 25, lg: 20, md: 18, sm: 13, xs: 10 }
                  }}>
                    {formattedDate(material.json_send_date)}
                  </Typography>
                </TableCellInfo>
              </TableRow>
              <TableRow>
                <TableCellInfo width="30%">
                  <Typography
                    sx={{
                      fontSize: { xl: 25, lg: 20, md: 18, sm: 13, xs: 10 }
                    }}
                    fontWeight="bold"
                  >
                    {LANGUAGE.REGISTERED_LOCATION}
                  </Typography>
                </TableCellInfo>
                <TableCellInfo width="70%" borderBottomColor={MainColor1}>
                  <Typography sx={{
                    fontSize: { xl: 25, lg: 20, md: 18, sm: 13, xs: 10 }
                  }}>
                    {material.user?.address}
                  </Typography>
                </TableCellInfo>
              </TableRow>
              <TableRow>
                <TableCellInfo width="30%">
                  <Typography
                    sx={{
                      fontSize: { xl: 25, lg: 20, md: 18, sm: 13, xs: 10 }
                    }}
                    fontWeight="bold"
                  >
                    {LANGUAGE.EMAIL}
                  </Typography>
                </TableCellInfo>
                <TableCellInfo width="70%" borderBottomColor={MainColor1}>
                  <Typography sx={{
                    fontSize: { xl: 25, lg: 20, md: 18, sm: 13, xs: 10 }
                  }}>
                    {material.user?.user_email}
                  </Typography>
                </TableCellInfo>
              </TableRow>
              <TableRow>
                <TableCellInfo width="30%">
                  <Typography
                    sx={{
                      fontSize: { xl: 25, lg: 20, md: 18, sm: 13, xs: 10 }
                    }}
                    fontWeight="bold"
                  >
                    {LANGUAGE.CONTRACTOR}
                  </Typography>
                </TableCellInfo>
                <TableCellInfo width="70%" borderBottomColor={MainColor1}>
                  <Typography sx={{
                    fontSize: { xl: 25, lg: 20, md: 18, sm: 13, xs: 10 }
                  }}>
                    {material.user.display_name}
                  </Typography>
                </TableCellInfo>
              </TableRow>

            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ width: "40%" }}>
          <StackCol
            alignItems="center"
            sx={{
              pt: 2,
              pl: 2
            }}>
            <img
              src={material.material_image_link}
              alt="material_photo"
              style={{
                border: "1px solid black",
                height: "100%",
                width: "100%",
                objectFit: 'fill',
                maxHeight: "500px"
              }} />
            <Typography
              sx={{
                fontSize: { xl: 25, lg: 20, md: 18, sm: 13, xs: 10 }
              }}
              fontWeight="bold"
            >
              {LANGUAGE.REGISTERED_IMAGE}
            </Typography>
          </StackCol>
        </Box>
      </StackRow>
      <StackRow justifyContent="center">
        <Typography sx={{
          fontSize: { lg: 20, md: 20, sm: 20, xs: 13 }
        }}>
          LOCAL TIME {formattedDate(currentTime)}
        </Typography>
      </StackRow>
    </StackCol>
  );
};

export default MaterialDetailUserPage;