import { Button, Paper, Stack } from '@mui/material';
import React, { useState } from 'react';
import StackRow from './StackRow';
import LANGUAGE from '../../utils/language.util';
import { BtnDeleteColor } from '../../styles/custom.style';
import AlertDeleteDialog from '../dialog/AlertDeleteDialog';
import { LoadingButton } from '@mui/lab';

const ButtonBox = (props) => {
  const [openDialog, setOpenDialog] = useState(false);

  const onClickDelete = () => {
    setOpenDialog(true);
  };

  const onDialogClickDelete = () => {
    setOpenDialog(false);
    props.onClickConfirmDelete();
  };

  return (
    <StackRow>
      <Stack sx={{ width: "30%", alignItems: "end" }}>
        <Button
          component={Paper}
          sx={{
            width: "100px",
            color: "black"
          }}
          onClick={props.onClickReturn}
        >
          {LANGUAGE.RETURN}
        </Button>
      </Stack>
      <Stack>
        <Button
          variant="contained"
          sx={{ width: "100px" }}
          onClick={props.onClickEdit}
        >
          {LANGUAGE.EDIT}
        </Button>
      </Stack>
      <Stack>
        <LoadingButton
          loading={props.isDeleting}
          variant="contained"
          sx={{
            width: "100px",
            bgcolor: BtnDeleteColor,
            "&:hover": {
              bgcolor: BtnDeleteColor,
            },
          }}
          onClick={onClickDelete}
        >
          {LANGUAGE.DELETE}
        </LoadingButton>
      </Stack>
      <AlertDeleteDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onDialogClickDelete={onDialogClickDelete}
      />
    </StackRow>
  );
};

export default ButtonBox;