import { createSlice } from "@reduxjs/toolkit";

export const appStateSlice = createSlice({
  name: "AppState",
  initialState: {
    appState: "",
    sidebarDisplay: false,
    breadcrumbs: [],
    materialTab: "information"
  },
  reducers: {
    setAppState: (state, action) => {
      state.appState = action.payload;
    },
    addBreadcrumb: (state, action) => {

      const tempBreads = [...state.breadcrumbs];

      const index = tempBreads.findIndex(b => b.level === action.payload.level);

      if (index > -1) {
        tempBreads[index] = action.payload;
        state.breadcrumbs = [...tempBreads];
      } else {
        const newBreads = [...state.breadcrumbs, action.payload];

        newBreads.sort((a, b) => a.level - b.level);

        state.breadcrumbs = newBreads;
      }
    },
    removeBreadcrumb: (state, action) => {
      const index = state.breadcrumbs.findIndex(b => b.level === action.payload.level);

      const newBreadcrumbs = [
        ...state.breadcrumbs.slice(0, index),
        ...state.breadcrumbs.slice(index + 1)
      ];

      state.breadcrumbs = newBreadcrumbs;
    },
    removeBreadcrumbAtLevel: (state, action) => {
      const index = state.breadcrumbs.findIndex(b => b.level === action.payload.level);

      const newBreadcrumbs = [
        ...state.breadcrumbs.slice(0, index),
        ...state.breadcrumbs.slice(index + 1)
      ];

      state.breadcrumbs = newBreadcrumbs;
    },
    resetBreadcrumb: (state) => {
      state.breadcrumbs = [];
    },
    setMaterialTab: (state, action) => {
      state.materialTab = action.payload;
    },
  }
});

export const {
  setAppState,
  addBreadcrumb,
  removeBreadcrumb,
  removeBreadcrumbAtLevel,
  resetBreadcrumb,
  setMaterialTab
} = appStateSlice.actions;

export default appStateSlice.reducer;