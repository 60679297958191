import { LoadingButton } from '@mui/lab';
import { Box, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import partnerApi from '../../api/partner.api';
import userApi from '../../api/user.api';
import { HeaderBox } from '../../components/common/HeaderBox';
import StackCol from '../../components/common/StackCol';
import StackRow from '../../components/common/StackRow';
import { TableRowTwoText } from '../../components/common/TableRowCustom';
import { routes, routesGen } from '../../configs/routes.config';
import { addBreadcrumb, removeBreadcrumbAtLevel } from '../../redux/features/app.state.slice';
import LANGUAGE from '../../utils/language.util';


const UserUpdatePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const { id } = useParams();
  const [userUpdate, setUserUpdate] = useState();
  const [isUpdateRequest, setIsUpdateRequest] = useState(false);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const getPartner = async () => {
      const { response, err } = await partnerApi.list({});

      if (err) {
        toast.error(err.message);
      };
      if (response) setPartners(response.partners);
    };

    if (user && user.user_role === 1) {
      getPartner();
    }
  }, [user]);

  useEffect(() => {
    const getUser = async () => {
      const { response, err } = await userApi.getOne(id);
      setUserUpdate(response);

      if (err) {
        toast.error(err.message);
        if (err.status === 404 || err.message === "user_id is invalid") {
          navigate(routes.USERS);
        }
      };
      if (response) setUserUpdate(response);
    };

    getUser();
  }, [id, navigate]);

  useEffect(() => {
    dispatch(addBreadcrumb({
      displayText: id,
      level: 3,
      link: routesGen.userDetail(id)
    }));
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(removeBreadcrumbAtLevel({ level: 3 }));
    };
  }, [dispatch]);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      role: userUpdate?.user_role || "",
      partner_id: userUpdate?.partner_id || "",
    },
    validationSchema: Yup.object({
      role: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.ROLE))
    }),
    onSubmit: async values => {
      setIsUpdateRequest(true);
      const params = {
        user_role: values.role,
        partner_id: values.partner_id,
      };
      const { response, err } = await userApi.update(id, params);
      if (response) {
        toast.success(LANGUAGE.UPDATED);
        setTimeout(() => {
          setIsUpdateRequest(false);
        }, 1000);
      }
      if (err) {
        toast.error(err.message);
        setIsUpdateRequest(false);
      }
    }
  });

  return (
    <StackCol>
      <HeaderBox title={LANGUAGE.USER_MANAGEMENT} />
      {userUpdate &&
        <Box component="form" onSubmit={form.handleSubmit}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRowTwoText
                  title={LANGUAGE.PERSON_NAME}
                  value={userUpdate.display_name}
                />
                <TableRowTwoText
                  title={LANGUAGE.POST_CODE}
                  value={userUpdate.postcode}
                />
                <TableRowTwoText
                  title={LANGUAGE.PREFECTURE}
                  value={userUpdate.prefecture}
                />
                <TableRowTwoText
                  title={LANGUAGE.ADDRESS_PREFECTURE_BELOW}
                  value={userUpdate.address}
                />
                <TableRowTwoText
                  title={LANGUAGE.BUILDING_NAME}
                  value={userUpdate.building}
                />
                <TableRowTwoText
                  title={LANGUAGE.TELEPHONE}
                  value={userUpdate.tel}
                />
                <TableRowTwoText
                  title={LANGUAGE.EMAIL}
                  value={userUpdate.user_email}
                />
                <TableRow>
                  <TableCell align='right' sx={{ minWidth: "30%" }}>
                    <Typography>
                      {LANGUAGE.PARTNER}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {partners &&
                      <Select
                        id="partner_id"
                        name="partner_id"
                        value={form.values.partner_id}
                        onChange={form.handleChange}
                        size="small"
                      >
                        {partners.map((partner, index) => (
                          <MenuItem
                            key={index}
                            value={partner.partner_id}
                          >
                            {partner.company_name}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='right' sx={{ minWidth: "30%", }}>
                    <Typography>
                      {LANGUAGE.ROLE}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Select
                      id="role"
                      name="role"
                      value={form.values.role}
                      onChange={form.handleChange}
                      size="small"
                    >
                      <MenuItem
                        value={2}
                        key={0}
                      >
                        {LANGUAGE.MANAGER}
                      </MenuItem>
                      <MenuItem
                        value={3}
                        key={1}
                      >
                        {LANGUAGE.USER}
                      </MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      }
      <StackRow justifyContent={"center"}>
        <Stack sx={{ width: "30%", alignItems: "end" }}>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isUpdateRequest}
            onClick={form.handleSubmit}
            sx={{ width: "100px" }}
          >
            {LANGUAGE.UPDATE}
          </LoadingButton>
        </Stack>
      </StackRow>
    </StackCol>
  );
};

export default UserUpdatePage;