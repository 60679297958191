import { LoadingButton } from "@mui/lab";
import { Box, Paper, Table, TableBody, TableContainer } from '@mui/material';
import { useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import userApi from '../../api/user.api';
import { HeaderBox } from "../../components/common/HeaderBox";
import StackCol from '../../components/common/StackCol';
import StackRow from '../../components/common/StackRow';
import { TableRowInForm, TableRowInFormTwoTextField, TableRowTwoText } from "../../components/common/TableRowCustom";
import { routes } from "../../configs/routes.config";
import { addBreadcrumb, removeBreadcrumbAtLevel } from "../../redux/features/app.state.slice";
import { setUser } from "../../redux/features/user.slice";
import { authUtils } from "../../utils/auth.utils";
import { formattedDate } from "../../utils/date.utils";
import LANGUAGE from '../../utils/language.util';

const ProfileEditPage = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const [isUpdateRequest, setIsUpdateRequest] = useState(false);

  const checkAuth = async () => {
    const userUpdated = await authUtils.isAuthenticated();
    if (userUpdated) {
      dispatch(setUser(userUpdated));
    }
  };

  useEffect(() => {
    dispatch(addBreadcrumb({
      displayText: LANGUAGE.EDIT,
      level: 3,
      link: routes.PROFILE_EDIT
    }));
  }, [dispatch]);


  useEffect(() => {
    return () => {
      dispatch(removeBreadcrumbAtLevel({ level: 3 }));
    };
  }, [dispatch]);


  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      postcode: user?.postcode || "",
      prefecture: user?.prefecture || "",
      address: user?.address || "",
      building: user?.building || "",
      tel: user?.tel || "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.FIRST_NAME)),
      last_name: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.LAST_NAME)),
    }),
    onSubmit: async values => {
      setIsUpdateRequest(true);

      const params = {
        first_name: values.first_name,
        last_name: values.last_name,
        postcode: values.postcode,
        prefecture: values.prefecture,
        address: values.address,
        building: values.building,
        tel: values.tel
      };

      const { response, err } = await userApi.profileUpdate(params);
      if (response) {
        toast.success(LANGUAGE.UPDATED);
        setTimeout(() => {
          checkAuth();
          setIsUpdateRequest(false);
        }, 1000);
      }

      if (err) {
        toast.error(err.message);
        setIsUpdateRequest(false);
      }
    }
  });

  return (
    <StackCol>
      <HeaderBox
        title={LANGUAGE.CUSTOMER_INFORMATION}
      />
      <Box component="form" onSubmit={form.handleSubmit}>
        {user &&
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRowInFormTwoTextField
                  title={LANGUAGE.APPLICANT_NAME}
                  idFirst="first_name"
                  idSecond="last_name"
                  form={form}
                ></TableRowInFormTwoTextField>
                <TableRowInForm
                  title={LANGUAGE.POST_CODE}
                  id="postcode"
                  form={form}
                  sx={{ width: "50%", pr: 2 }}
                ></TableRowInForm>
                <TableRowInForm
                  title={LANGUAGE.PREFECTURE}
                  id="prefecture"
                  form={form}
                ></TableRowInForm>
                <TableRowInForm
                  title={LANGUAGE.ADDRESS_PREFECTURE_BELOW}
                  id="address"
                  form={form}
                ></TableRowInForm>
                <TableRowInForm
                  title={LANGUAGE.BUILDING_NAME}
                  id="building"
                  form={form}
                ></TableRowInForm>
                <TableRowInForm
                  title={LANGUAGE.TELEPHONE}
                  id="tel"
                  form={form}
                ></TableRowInForm>
                <TableRowTwoText
                  title={LANGUAGE.CREATED_DATE}
                  value={formattedDate(user.created_date)}
                >
                </TableRowTwoText>
                <TableRowTwoText
                  title={LANGUAGE.UPDATED_DATE}
                  value={formattedDate(user.updated_date)}
                >
                </TableRowTwoText>
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Box>
      <StackRow justifyContent={"center"}>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={isUpdateRequest}
          onClick={form.handleSubmit}
        >
          {LANGUAGE.UPDATE}
        </LoadingButton>
      </StackRow>
    </StackCol >
  );
};

export default ProfileEditPage;