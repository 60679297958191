import { callAPI } from "./axios.client";


const userEndpoints = {
  list: "users",
  getOne: ({ id }) => `users/${id}`,
  update: ({ id }) => `users/${id}`,
  delete: ({ id }) => `users/${id}`,
  profile: "users/profile",
};


const userApi = {
  list: async (query) => await callAPI("get", `${userEndpoints.list}?page=${query.page ? query.page : 0}&partner=${query.partner ? query.partner : ""}&name=${query.name ? query.name : ""}&email=${query.email ? query.email : ""}`),
  getOne: async (id) => await callAPI("get", userEndpoints.getOne({ id })),
  update: async (id, params) => await callAPI("put", userEndpoints.update({ id }), params),
  delete: async (id) => await callAPI("delete", userEndpoints.delete({ id })),
  profile: async () => await callAPI("get", userEndpoints.profile),
  profileUpdate: async (params) => await callAPI("put", userEndpoints.profile, params),
};

export default userApi;
