import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import LANGUAGE from '../../utils/language.util';

const AlertDeleteDialog = (props) => {
  const handleClose = () => {
    props.setOpenDialog(false);
  };

  return (
    <>
      <Dialog
        open={props.openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          削除確認
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            この操作は元に戻せません。削除しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            {LANGUAGE.CANCEL}
          </Button>
          <Button onClick={props.onDialogClickDelete} autoFocus variant="outlined" color="error">
            {LANGUAGE.DELETE}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlertDeleteDialog;