import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import LANGUAGE from '../../utils/language.util';

const AlertErrorDialog = (props) => {
  const { setOpenDialog, openDialog, title, message } = props;
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" autoFocus>
            {LANGUAGE.GOT_IT}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlertErrorDialog;