import dayjs from 'dayjs';

export const formattedDate = (date) => {
  if (date && date !== "0000-00-00 00:00:00.000") {
    return dayjs(date).format("YYYY/MM/DD HH:mm:ss");
  }
  return "";
};

export const formattedDateNotTime = (date) => {
  if (date && date !== "0000-00-00 00:00:00.000") {
    return dayjs(date).format("YYYY/MM/DD");
  }
  return "";
};

export const getDetailTimeFromDate = (date) => {
  const dateObject = new Date(date);

  const formatDateComponent = (component) => String(component).padStart(2, '0');

  return {
    year: dateObject.getFullYear(),
    month: formatDateComponent(dateObject.getMonth() + 1),
    day: formatDateComponent(dateObject.getDate()),
    hour: formatDateComponent(dateObject.getHours()),
    minute: formatDateComponent(dateObject.getMinutes()),
  };
};