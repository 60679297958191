import axios from 'axios';
import queryString from 'query-string';
import { arrayBufferToBase64 } from '../utils/image.utils';

// const baseUrl = 'http://127.0.0.1:5000/api/v1/';
//const baseUrl = 'http://192.168.1.5:5000/api/v1/';
const baseUrl = 'https://api.sora-memorial.com/api/v1/';
const getToken = () => localStorage.getItem('access_token');

const axiosClient = axios.create({
  baseURL: baseUrl,
  paramsSerializer: params => queryString.stringify({ params })
});

const AuthorizeHeader = () => {
  return {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${getToken()}`
  };
};


axiosClient.interceptors.request.use(async config => {
  return {
    ...config,
    headers: {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${getToken()}`
    }
  };
});

axiosClient.interceptors.response.use(response => {
  if (response && response.data) return response.data;
  return response;
}, (err) => {
  throw err.response.data;
});

const callAPI = async (method, endpoint, params) => {
  try {
    const response = await axiosClient({
      method: method,
      url: endpoint,
      data: params,
    });
    return { response };
  } catch (err) {
    return { err };
  }
};

const callAPIGetFile = async (method, endpoint, params) => {
  try {
    const response = await axiosClient({
      method: method,
      url: endpoint,
      data: params,
      responseType: 'blob'
    });
    return { response };
  } catch (err) {
    return { err };
  }
};

const callAPIGetFileBuffer = async (method, endpoint, params) => {
  try {
    const response = await axiosClient({
      method: method,
      url: endpoint,
      data: params,
      responseType: 'arraybuffer'
    });

    return arrayBufferToBase64(response);
  } catch (err) {
    return { err };
  }
};

export {
  axiosClient,
  callAPI,
  baseUrl,
  AuthorizeHeader,
  callAPIGetFile,
  callAPIGetFileBuffer
};