import { Font, Text } from '@react-pdf/renderer';
import React from 'react';
import NotoSerifJPBold from "../../assets/fonts/NotoSerifJP/NotoSerifJP-Bold.otf";
import NotoSerifJPMedium from "../../assets/fonts/NotoSerifJP/NotoSerifJP-Medium.otf";
import NotoSerifJPSemiBold from "../../assets/fonts/NotoSerifJP/NotoSerifJP-SemiBold.otf";

const CertificateMessage = (props) => {
  const { sentMessage } = props;

  Font.register({
    family: 'Noto Serif JP',
    fonts: [
      {
        src: NotoSerifJPBold,
        fontWeight: 'bold'
      },
      {
        src: NotoSerifJPMedium,
        fontWeight: 'medium',
      },
      {
        src: NotoSerifJPSemiBold,
        fontWeight: 'semibold',
      }
    ]
  });

  return (
    <>
      {/* <Text x="50%" y="435" textAnchor="middle"
        style={{
          fontFamily: "Noto Serif JP",
          fontWeight: "bold",
          fontSize: "18px",
          fill: "#056fb8",
        }}>
        {firstMessage}
      </Text>
      <Text x="50%" y="466" textAnchor="middle"
        style={{
          fontFamily: "Noto Serif JP",
          fontWeight: "bold",
          fontSize: "18px",
          fill: "#056fb8",
        }}>
        {secondMessage}
      </Text> */}
      <Text x="50%" y="520" textAnchor="middle"
        style={{
          fontFamily: "Noto Serif JP",
          fontWeight: "medium",
          fontSize: "16px",
        }}>
        {sentMessage}
      </Text>
      <Text x="50%" y="545" textAnchor="middle"
        style={{
          fontFamily: "Noto Serif JP",
          fontSize: "15px",
          fontWeight: "medium",
        }}>
        お預かりしたデータを空に送信したことを証明いたします。
      </Text>
    </>
  );
};

export default CertificateMessage;