import Camera from "@arcgis/core/Camera.js";
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer.js';
import Map from "@arcgis/core/Map.js";
import SceneView from "@arcgis/core/views/SceneView.js";
import Popup from "@arcgis/core/widgets/Popup.js";
import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import materialApi from "../../api/material.api";
import n2yoApi from '../../api/n2yo.api';
import { routesGen } from "../../configs/routes.config";
import { addBreadcrumb, removeBreadcrumbAtLevel } from "../../redux/features/app.state.slice";
import { createGraphicSat, createGraphicUser, findNearestCoordinates, tleToGeographic, trackFeatures } from '../../utils/sat.utils';
import StackCol from './../../components/common/StackCol';
import { formattedDate } from './../../utils/date.utils';
import LANGUAGE from "../../utils/language.util";
import StackRow from './../../components/common/StackRow';

let interval = null;

const SatellitePage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();


  const [satellite, setSatellite] = useState(null);
  const [material, setMaterial] = useState();
  const [coordinate, setCoordinate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getMaterial = async () => {
      const { response, err } = await materialApi.getOne(id);

      if (err) toast.error(err.message);
      if (response) {
        setMaterial(response);
      };
    };
    getMaterial();
  }, [id]);

  useEffect(() => {
    dispatch(addBreadcrumb({
      displayText: id,
      level: 3,
      link: routesGen.materialDetail(id)
    }));
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(removeBreadcrumbAtLevel({ level: 3 }));
    };
  }, [dispatch]);

  useEffect(() => {
    const getSat = async (id) => {
      const { response, err } = await n2yoApi.getById(id);
      if (response) {
        setSatellite(response);
      }
      if (err) toast.error(err.message);
    };
    if (material) {
      getSat(material.sat_id);
    }
  }, [material]);

  useEffect(() => {
    const initMap = async () => {
      try {
        // Create Map
        const mainMap = new Map({
          basemap: 'satellite',
          ground: "world-elevation"
        });

        // Create SceneView
        const mainView = new SceneView({
          container: "map",
          map: mainMap,
          ui: {
            components: [
              'zoom',
              'compass'
            ]
          },
          popup: new Popup({
            dockEnabled: true,
            dockOptions: {
              breakpoint: false
            }
          }),
          environment: {
            lighting: {
              type: "virtual"
            },
            atmosphereEnabled: true,
            atmosphere: {
              quality: 'high'
            },
          },
          constraints: {
            altitude: {
              max: 110000000
            }
          }
        });

        await mainView.when();

        setIsLoading(false);

        // Create Layer
        const satelliteLayer = new GraphicsLayer();
        const userLayer = new GraphicsLayer();
        const satelliteTracks = new GraphicsLayer();
        mainMap.addMany([satelliteLayer, userLayer, satelliteTracks]);

        const line1 = satellite.tle_lines[0];
        const line2 = satellite.tle_lines[1];

        const satelliteLoc = tleToGeographic(new Date(), line1, line2);

        // Create Camera
        const cam = new Camera({
          position: {
            spatialReference: {
              latestWkid: 3857,
              wkid: 102100
            },
            latitude: satelliteLoc.y,
            longitude: satelliteLoc.x,
            z: 55000000
          }
        });
        mainView.goTo(cam);

        // Create Graphic SAT
        const graphic = createGraphicSat(line1, line2);
        satelliteLayer.add(graphic);

        // Create Graphic Location User
        if (material.user_latitude) {
          const graphicUser = createGraphicUser(material.user_latitude, material.user_longitude);
          userLayer.add(graphicUser);
        }

        // Create Graphic Track Features
        const track = await trackFeatures(line1, line2);
        satelliteTracks.add(track);

        if (material.user_latitude) {
          const nearestCoordinate = await findNearestCoordinates(line1, line2, material.user_latitude, material.user_longitude);
          setCoordinate(nearestCoordinate);
        }

        mainView.ui.add("topBar", "top-right");

        interval = setInterval(() => {
          satelliteLayer.removeAll();
          const graphic = createGraphicSat(line1, line2);

          satelliteLayer.add(graphic);
        }, 2000);
      } catch (error) {
        console.error("SceneView rejected:", error);
      }
    };

    if (satellite && material) {
      initMap();
    }
  }, [satellite, material]);

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <StackCol sx={{
      alignItems: "center",
      justifyContent: "center"
    }}>
      <Box id="map" sx={{
        padding: 0,
        margin: 0,
        height: '92vh',
        width: '100%',
      }}>
      </Box>
      <Box sx={{
        display: isLoading ? "" : "none",
        position: "absolute",
        zIndex: 1000,
      }}>
        <CircularProgress
          size={80}
        />
      </Box>
      <StackRow id="topBar">
        {coordinate && !isLoading &&
          <Box sx={{
            width: { lg: 250, md: 250, sm: 200, xs: 125 },
            pt: { lg: 0, md: 2, sm: 2, xs: "10px" }
          }}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align='center' size="small"
                      sx={{
                        padding: { sm: "6px", xs: "3px" }
                      }}>
                      <Typography sx={{
                        fontWeight: "bold",
                        color: 'white',
                        fontSize: { lg: 15, md: 15, sm: 13, xs: 7 }
                      }}>
                        {LANGUAGE.TODAY_SKY_MEMO_TIME}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow size="small">
                    <TableCell align='center' size="small">
                      <Typography sx={{
                        color: 'white',
                        fontSize: { lg: 16, md: 15, sm: 13, xs: 7 }
                      }}>
                        {formattedDate(coordinate.time)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow align='center'>
                    <Box p={2}>
                      {material.material_image_link &&
                        <img
                          src={material.material_image_link}
                          alt="material_photo"
                          style={{
                            width: '80%'
                          }}
                        />
                      }
                    </Box>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        }
      </StackRow>
    </StackCol >
  );
};

export default SatellitePage;