import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, ListItemButton, ListItemIcon, ListItemText, Menu, Paper, Typography, colors } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import menuConfigs from "../../configs/menu.configs";
import { MenuActiveColor } from '../../styles/custom.style';

const UserMenu = () => {
  const { user } = useSelector((state) => state.user);
  const { appState } = useSelector((state) => state.appState);

  const [anchorEl, setAnchorEl] = useState(null);

  const toggleMenu = (e) => setAnchorEl(e.currentTarget);

  return (
    <>
      {user && (
        <>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={toggleMenu}
            sx={{
              mr: 2,
              display: {
                lg: 'none',
              },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            components={Paper}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{
              marginTop: "15px",
            }}
          >
            {menuConfigs.main.map((item, index) => (
              (user && item.roles.includes(user.user_role)) && (
                <ListItemButton
                  component={Link}
                  to={item.path}
                  key={index}
                  onClick={() => setAnchorEl(null)
                  }
                  sx={{
                    bgcolor: (item.state === appState) ? MenuActiveColor : "",
                    color: (item.state === appState) ? colors.common.black : "",
                    "&:hover": {
                      bgcolor: (item.state === appState) ? MenuActiveColor : "",
                      color: (item.state === appState) ? colors.common.black : "",
                    },
                    height: 60,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "35px"
                    }}
                  >{item.icon}</ListItemIcon>
                  <ListItemText disableTypography primary={
                    <Typography>{item.title(user.user_role)}</Typography>
                  } />
                </ListItemButton>
              )
            ))}
          </Menu>
        </>
      )}
    </>
  );
};

export default UserMenu;