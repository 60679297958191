import { Box, Paper, Typography } from '@mui/material';
import React from 'react';

const MPaper = (props) => {
  return (
    <Paper
      elevation={0}
      sx={{
        ...props.sx,
        p: 3,
        height: props.fullHeight ? "100%" : "unset",
        boxShadow: "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px"
      }}
    >
      {props.title && (
        <Typography variant="body1" fontWeight={600} mb={3}>
          {props.title}
        </Typography>
      )}
      <Box>
        {props.children}
      </Box>
    </Paper>
  );
};

export default MPaper;