import React from 'react';
import StackCol from './StackCol';
import { Box, CircularProgress } from '@mui/material';

const LoadingPage = () => {
  return (
    <StackCol
      alignItems="center"
      justifyContent="center"
      sx={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
      }}>
      <Box>
        <CircularProgress
          size={40}
        />
      </Box>
    </StackCol>
  );
};

export default LoadingPage;