import { LoadingButton } from "@mui/lab";
import { Box, FormControl, FormHelperText } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { AuthorizeHeader, baseUrl } from '../../api/axios.client';
import materialApi, { materialEndpoints } from '../../api/material.api';
import { BoxUploadImage } from "../../components/common/BoxUploadFile";
import { HeaderBox } from "../../components/common/HeaderBox";
import MPaper from '../../components/common/MPaper';
import StackCol from '../../components/common/StackCol';
import { TextFieldCustom } from "../../components/common/TextFieldCustom";
import { routesGen } from "../../configs/routes.config";
import { addBreadcrumb, removeBreadcrumbAtLevel } from "../../redux/features/app.state.slice";
import LANGUAGE from '../../utils/language.util';

const MaterialUpdatePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const [fileMaterial, setFileMaterial] = useState(null);
  // const [videoCapsule, setVideoCapsule] = useState(null);
  const [isRegisterRequest, setIsRegisterRequest] = useState(false);
  const [material, setMaterial] = useState();

  const minSelectableDate = dayjs().add(3, 'day');

  useEffect(() => {
    const getMaterial = async () => {
      const { response, err } = await materialApi.getOne(id);

      if (err) toast.error(err.message);
      if (response) {
        if (response.nft_token) {
          navigate(routesGen.materialDetail(response.material_id));
        }
        setMaterial(response);
        console.log(response);
      };
    };
    getMaterial();
  }, [id, navigate]);

  useEffect(() => {
    dispatch(addBreadcrumb({
      displayText: id,
      level: 3,
      link: routesGen.materialDetail(id)
    }));
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(removeBreadcrumbAtLevel({ level: 3 }));
    };
  }, [dispatch]);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      nick_name: material ? material.nickname : "",
      design_type: material ? material.design_type : "",
      desired_send_date: material ? material.desired_send_date : "",
      message: material ? material.message : "",
      capsules_open_date: material ? material.capsules_open_date : "",
      capsules_message: material ? material.capsules_message : "",
      monthly_memorial_date: material ? material.monthly_memorial_date : null,
      email_notification_1: material ? material.email_notification_1?.trim() : "",
      email_notification_2: material ? material.email_notification_2?.trim() : "",
      email_notification_3: material ? material.email_notification_3?.trim() : "",
      email_notification_4: material ? material.email_notification_4?.trim() : "",
    },
    validationSchema: Yup.object({
      nick_name: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.NICKNAME)),
      monthly_memorial_date: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.MONTHLY_MEMORIAL_DATE)),
      email_notification_1: Yup.string().trim().nullable()
        .email(LANGUAGE.FIELD_INVALID(LANGUAGE.EMAIL)),
      email_notification_2: Yup.string().trim().nullable()
        .email(LANGUAGE.FIELD_INVALID(LANGUAGE.EMAIL)),
      email_notification_3: Yup.string().trim().nullable()
        .email(LANGUAGE.FIELD_INVALID(LANGUAGE.EMAIL)),
      email_notification_4: Yup.string().trim().nullable()
        .email(LANGUAGE.FIELD_INVALID(LANGUAGE.EMAIL)),
    }),
    onSubmit: async values => {
      setIsRegisterRequest(true);
      const formData = new FormData();
      formData.append('nickname', values.nick_name);

      const fields = [
        'design_type',
        'desired_send_date',
        'message',
        'capsules_open_date',
        'capsules_message',
        'monthly_memorial_date',
        'email_notification_1',
        'email_notification_2',
        'email_notification_3',
        'email_notification_4',
      ];
      fields.forEach(field => {
        if (values[field]) {
          formData.append(field, values[field]);
        }
      });

      if (fileMaterial) {
        formData.append('material', fileMaterial);
      }
      // if (videoCapsule) {
      //   formData.append('capsules_video', videoCapsule);
      // }

      axios({
        method: "put",
        url: baseUrl + materialEndpoints.create + "/" + id,
        data: formData,
        headers: AuthorizeHeader(),
      })
        .then(function () {
          toast.success(LANGUAGE.UPDATED);
          setTimeout(() => {
            navigate(routesGen.materialDetail(id));
          }, 2000);
        })
        .catch(function (error) {
          if (error && error.data) {
            toast.error(error.data.message);
          } else {
            toast.error(error.response.data.message);
          }
          setIsRegisterRequest(false);
        });
    }
  });

  // Function to handle file input change
  const handleMaterialFileChange = (event) => {
    const file = event.target.files[0];
    setFileMaterial(file);
  };

  const handleDateChange = (newDate) => {
    try {
      // Convert Date to ISO timestamp
      const isoTimestamp = newDate.toISOString();
      // Update the form field value
      form.setFieldValue('desired_send_date', isoTimestamp);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMonthlyDateChange = (newDate) => {
    try {
      const isoTimestamp = newDate.toISOString();
      form.setFieldValue('monthly_memorial_date', isoTimestamp);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleCapsuleDateChange = (newDate) => {
  //   try {
  //     // Convert Date to ISO timestamp
  //     const isoTimestamp = newDate.toISOString();
  //     // Update the form field value
  //     form.setFieldValue('capsules_open_date', isoTimestamp);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const handleCapsuleVideoChange = (event) => {
  //   const file = event.target.files[0];
  //   setVideoCapsule(file);
  // };

  return (
    <StackCol>
      <HeaderBox title={LANGUAGE.REGISTRATION_INFORMATION} />
      <MPaper>
        <Box
          component="form"
          onSubmit={form.handleSubmit}
          sx={{
            display: "flex",
            justifyContent: "center"
          }}>
          <StackCol sx={{ width: "600px" }}>
            <TextFieldCustom
              id={"nick_name"}
              name={"nick_name"}
              form={form}
              label={LANGUAGE.TARGET_PERSON_NAME}
            />
            <TextFieldCustom
              id={"message"}
              name={"message"}
              form={form}
              label={LANGUAGE.MESSAGE}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={LANGUAGE.DESIRED_SEND_DATE}
                value={form.values.desired_send_date ? dayjs(form.values.desired_send_date) : null}
                onChange={(newValue) => handleDateChange(newValue)}
                format="YYYY/MM/DD"
                minDate={minSelectableDate}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl>
                <DatePicker
                  label={LANGUAGE.MONTHLY_MEMORIAL_DATE}
                  value={(form.values.monthly_memorial_date && form.values.monthly_memorial_date !== "0000-00-00 00:00:00.000") ? dayjs(form.values.monthly_memorial_date) : null}
                  onChange={(newValue) => handleMonthlyDateChange(newValue)}
                  format="YYYY/MM/DD"
                />
                {form.touched.monthly_memorial_date && form.errors.monthly_memorial_date &&
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {form.errors.monthly_memorial_date}
                  </FormHelperText>
                }
              </FormControl>
            </LocalizationProvider>
            <TextFieldCustom
              id={"email_notification_1"}
              name={"email_notification_1"}
              form={form}
              label={LANGUAGE.EMAIL_NOTIFICATION_1}
            />
            <TextFieldCustom
              id={"email_notification_2"}
              name={"email_notification_2"}
              form={form}
              label={LANGUAGE.EMAIL_NOTIFICATION_2}
            />
            <TextFieldCustom
              id={"email_notification_3"}
              name={"email_notification_3"}
              form={form}
              label={LANGUAGE.EMAIL_NOTIFICATION_3}
            />
            <TextFieldCustom
              id={"email_notification_4"}
              name={"email_notification_4"}
              form={form}
              label={LANGUAGE.EMAIL_NOTIFICATION_4}
            />
            <BoxUploadImage
              title={LANGUAGE.TRACE_IMAGE_UPLOAD}
              file={fileMaterial}
              handleFileChange={handleMaterialFileChange}
              accept=".jpg, .png, .jpeg"
            />
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={LANGUAGE.CAPSULES_OPEN_DATE}
                value={form.values.capsules_open_date ? dayjs(form.values.capsules_open_date) : null}
                onChange={(newValue) => handleCapsuleDateChange(newValue)}
                format="YYYY/MM/DD"
              />
            </LocalizationProvider>
            <TextFieldCustom
              id={"capsules_message"}
              name={"capsules_message"}
              form={form}
              label={LANGUAGE.CAPSULES_MESSAGE}
            />
            <BoxUploadVideo
              title={LANGUAGE.CAPSULES_VIDEO}
              file={videoCapsule}
              handleFileChange={handleCapsuleVideoChange}
              accept=".mp4, .avi, .mov, .mkv"
            /> */}
          </StackCol>
        </Box>
      </MPaper>
      <StackCol alignItems={"center"} spacing={4}>
        <LoadingButton
          sx={{
            width: "100px"
          }}
          variant="contained"
          type="submit"
          loading={isRegisterRequest}
          onClick={form.handleSubmit}
        >
          {LANGUAGE.REGISTER}
        </LoadingButton>
      </StackCol>
    </StackCol>
  );
};

export default MaterialUpdatePage;