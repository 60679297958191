import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { Avatar, ListItemButton, ListItemIcon, ListItemText, Menu, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { IMAGES } from "../../assets";
import menuConfigs from "../../configs/menu.configs";
import { setUser } from "../../redux/features/user.slice";
import { routes } from './../../configs/routes.config';
import StackRow from "./StackRow";

const UserMenu = () => {
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const toggleMenu = (e) => setAnchorEl(e.currentTarget);

  const requestLogout = () => {
    dispatch(setUser(null));
    navigate(routes.SIGNIN);
  };

  return (
    <>
      {user && (
        <>
          <StackRow spacing={1} alignItems='center'>
            <Avatar src={IMAGES.avatar} sx={{ width: 40, height: 40 }} />
            <Typography
              variant="h6"
              sx={{ cursor: "pointer", userSelect: "none", color: "black" }}
              onClick={toggleMenu}
            >
              {user.display_name}
            </Typography>
          </StackRow>
          <Menu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            components={Paper}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{
              marginTop: "15px",
            }}
          >
            {menuConfigs.user.map((item, index) => (
              <ListItemButton
                component={Link}
                to={item.path}
                key={index}
                onClick={() => setAnchorEl(null)
                }
              >
                <ListItemIcon
                  sx={{
                    minWidth: "35px"
                  }}
                >{item.icon}</ListItemIcon>
                <ListItemText disableTypography primary={
                  <Typography>{item.title}</Typography>
                } />
              </ListItemButton>
            ))}
            <ListItemButton
              onClick={() => requestLogout()}
            >
              <ListItemIcon
                sx={{
                  minWidth: "35px"
                }}
              ><LogoutOutlinedIcon /></ListItemIcon>
              <ListItemText disableTypography primary={
                <Typography>サインアウト</Typography>
              } />
            </ListItemButton>
          </Menu>
        </>
      )}
    </>
  );
};

export default UserMenu;