import { callAPI } from "./axios.client";

export const n2yoEndpoints = {
  getById: ({ id }) => `n2yo/tle/${id}`,
};


const n2yoApi = {
  getById: async (id) => await callAPI("get", n2yoEndpoints.getById({ id }))
};

export default n2yoApi;
