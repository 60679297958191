export const IMAGES = {
  loginBg: require("./images/background.png"),
  logo: require("./images/logo.png"),
  logo_user: require("./images/logo_user.png"),
  logo_only: require("./images/logo_only.png"),
  avatar: require("./images/avatar.png"),
  certificate_background: require("./images/certificate_background.png"),
  certificate_decor: require("./images/certificate_decor.png"),
  certificate_decor_with_text: require("./images/certificate_decor_with_text.png"),
  certificate_decor_svg: require("./images/certificate_decor.svg"),
  certificate_frame: require("./images/certificate_frame.png"),
  certificate_frame_with_bg: require("./images/certificate_frame_with_bg.png"),
  certificate_footer_full: require("./images/certificate_footer_full.png"),
  sora_memory: require("./images/sora_memory.png"),
  sat_demo: require("./images/sat_demo.png"),
  satellite_icon: require("./images/satellite.png"),
  login_bg: require("./images/login_background.png"),
};

export const VIDEOS = {
  user_screen_bg: require("./videos/user_screen_bg.mp4"),
  where_is_now: require("./videos/where_is_now.mp4"),
};