import { Box, Stack, Toolbar, colors } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

import Sidebar from '../common/Sidebar';
import Topbar from '../common/Topbar';

const sidebarWidth = 350;

const MainLayout = () => {
  return (
    <Box sx={{
      minHeight: "100vh",
      backgroundColor: colors.grey["100"],
    }}>
      {/* header */}
      <Topbar />
      {/* header */}
      <Toolbar />
      <Box display="flex">
        {/* sidebar */}
        <Sidebar sidebarWidth={sidebarWidth} />
        {/* sidebar */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { xl: "100%", lg: "100%", md: "100%", sm: "90%", xs: "90%" },
            overflowY: "hidden"
          }}
        >
          <Stack spacing={2}>
            <div>
              <Outlet />
            </div>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;