import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      if (action.payload === null) {
        localStorage.removeItem("access_token");
        state.user = null;
      } else {
        if (action.payload.user) {
          state.user = action.payload.user;
        }
        if (action.payload.access_token) {
          localStorage.setItem("access_token", action.payload.access_token);
        }
      }
    },
  }
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;