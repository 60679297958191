import {
  Button, Pagination, Paper, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TableRow
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import materialApi from '../../api/material.api';
import { HeaderBox, HeaderBoxWithButton } from '../../components/common/HeaderBox';
import StackCol from '../../components/common/StackCol';
import StackRow from '../../components/common/StackRow';
import { routes, routesGen } from '../../configs/routes.config';
import { formattedDate, formattedDateNotTime } from '../../utils/date.utils';
import LANGUAGE from '../../utils/language.util';

const MaterialPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const [materials, setMaterials] = useState([]);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState({ page: 1 });
  const [pageCount, setPageCount] = useState(1);
  const [getParamSuccess, setGetParamSuccess] = useState(false);

  useEffect(() => {
    getParams();
  }, []);

  useEffect(() => {
    const getListMaterials = async () => {
      const { response, err } = await materialApi.list(query);
      if (response) {
        setMaterials(response.materials);
        setPageCount(Math.ceil(response.count / 10));
      }
      if (err) toast.error(err.message);
    };

    if (getParamSuccess) {
      getListMaterials();
    }
  }, [dispatch, query, getParamSuccess]);

  const handleChange = (event, value) => {
    setPage(value);
    setQuery({ page: value });
    addParams(value);
  };

  const addParams = (page) => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("page", page);
    window.history.pushState({ path: currentUrl.href }, "", currentUrl.href);
  };

  const getParams = () => {
    const url = new URL(window.location.href);
    const pageParam = url.searchParams.get("page");
    if (pageParam !== null) {
      const page = parseInt(pageParam, 10);
      setPage(page);
      setQuery({ page: page });
    }
    setGetParamSuccess(true);
  };

  const onclickDetail = (id) => {
    if (user.user_role === 3) {
      return navigate(routesGen.certificateDetail(id));
    }
    navigate(routesGen.materialDetail(id));
  };

  return (
    <StackCol>
      {(user && user.user_role === 1) ?
        <HeaderBoxWithButton
          title={LANGUAGE.REGISTRATION_INFORMATION_LIST}
          titleButton={LANGUAGE.MATERIAL_ADD}
          to={routes.MATERIAL_CREATE}
        /> :
        <HeaderBox
          title={LANGUAGE.REGISTRATION_INFORMATION_LIST}
        ></HeaderBox>}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{LANGUAGE.TARGET_PERSON_NAME}</TableCell>
              <TableCell>{LANGUAGE.DESIRED_SEND_DATE}</TableCell>
              <TableCell>{LANGUAGE.SEND_DATE}</TableCell>
              <TableCell>{LANGUAGE.CERTIFICATE_ISSUE_DATE}</TableCell>
              <TableCell>{LANGUAGE.PHOTO_OF_THE_DECEASED}</TableCell>
              <TableCell sx={{ width: 100 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {materials && materials.map((material, index) => (
              <TableRow key={index}>
                <TableCell>{material.nickname}</TableCell>
                <TableCell>
                  {formattedDateNotTime(material.desired_send_date)}
                </TableCell>
                <TableCell>
                  {formattedDate(material.json_send_date)}
                </TableCell>
                <TableCell>
                  {formattedDate(material.certificate_issue_date)}
                </TableCell>
                <TableCell>
                  {material.material_image_link &&
                    <img
                      src={material.material_image_link}
                      alt="material_photo"
                      style={{
                        width: '50px'
                      }}
                    />
                  }
                </TableCell>
                <TableCell>
                  <Button
                    color="primary"
                    onClick={() => onclickDetail(material.material_id)}
                  >
                    {LANGUAGE.DETAIL}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <StackRow spacing={2}>
        <Pagination
          variant="outlined"
          shape="rounded"
          color="primary"
          count={pageCount}
          page={page}
          onChange={handleChange}
        />
      </StackRow>
    </StackCol>
  );
};

export default MaterialPage;