import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import MBreadcrumb from './MBreadCrumb';
import StackRow from './StackRow';
import { AddBoxIcon } from './Icon';

const HeaderBox = (props) => {
  return (
    <Box pl={2}>
      <StackRow alignItems="center" spacing={0}>
        <Typography sx={{
          fontSize: 22,
          paddingRight: 5,
          fontWeight: "bold"
        }}>
          {props.title}
        </Typography>
      </StackRow>
      <MBreadcrumb />
    </Box>
  );
};

const HeaderBoxWithButton = (props) => {
  return (
    <Box pl={2}>
      <StackRow alignItems="center" spacing={0}>
        <Typography sx={{
          fontSize: 22,
          paddingRight: 5,
          fontWeight: "bold"
        }}>
          {props.title}
        </Typography>
        <IconButton color="primary" aria-label="add"
          component={Link}
          to={props.to}
        >
          <AddBoxIcon />
        </IconButton>
        <Typography>
          {props.titleButton}
        </Typography>
      </StackRow>
      <MBreadcrumb />
    </Box>
  );
};

export {
  HeaderBox,
  HeaderBoxWithButton
};

