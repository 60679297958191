import { CompanyIcon, GroupIcon, LockResetIcon, ManageAccountsIcon, ManagementIcon } from '../components/common/Icon';

import { APP_STATE } from "./constants.config";
import LANGUAGE from "../utils/language.util";

const main = [
  {
    title: () => {
      return LANGUAGE.PARTNER_MANAGEMENT;
    },
    path: "/partners",
    icon: <CompanyIcon />,
    state: APP_STATE.PARTNER,
    roles: [1]
  },
  {
    title: () => {
      return LANGUAGE.USER_MANAGEMENT;
    },
    path: "/users",
    icon: <GroupIcon />,
    state: APP_STATE.USER,
    roles: [1, 2]
  },
  {
    title: (role) => {
      return role === 1 ? LANGUAGE.REGISTRATION_INFORMATION : LANGUAGE.MANAGEMENT_SCREEN;
    },
    path: "/materials",
    icon: <ManagementIcon />,
    state: APP_STATE.MATERIAL,
    roles: [1, 2, 3]
  }
];

const user = [
  {
    title: "プロフィール",
    path: "/setting/profile",
    icon: <ManageAccountsIcon />,
    state: APP_STATE.SETTING,
    roles: [1, 2, 3]
  },
  {
    title: "パスワード変更",
    path: "/setting/password",
    icon: <LockResetIcon />,
    state: APP_STATE.SETTING,
    roles: [1, 2, 3]
  }
];

const menuConfigs = { main, user };

export default menuConfigs;