import { twoline2satrec, eciToGeodetic, propagate, gstime, degreesLat, degreesLong } from 'satellite.js';
import Graphic from '@arcgis/core/Graphic.js';

import { IMAGES } from '../assets';
import { LocationIconPNG } from '../components/common/Icon';
import moment from 'moment';

const getAttributes = (line1) => {
  const designator = line1.substring(9, 16);
  const launchYear = designator.substring(0, 2);
  const fullLaunchYear = Number(launchYear) >= 57 ? `19${launchYear}` : `20${launchYear}`;
  const launchNum = Number(designator.substring(2, 5)).toString();
  const noradId = Number(line1.substring(3, 7));

  return {
    fullLaunchYear,
    launchNum,
    noradId
  };
};

// Function to convert TLE to geographic location
const tleToGeographic = (time, line1, line2) => {
  try {
    // Parse TLE data
    const satrec = twoline2satrec(line1, line2);

    // Get satellite position in ECI coordinates
    const positionEci = propagate(satrec, time);

    // Convert ECI coordinates to geographic coordinates
    const gmst = gstime(time);
    const positionGd = eciToGeodetic(positionEci.position, gmst);

    // Extract latitude, longitude, and altitude
    const latitude = degreesLat(positionGd.latitude);
    const longitude = degreesLong(positionGd.longitude);
    const altitude = positionGd.height;

    return {
      type: "point", // Autocasts as new Point()
      x: longitude,
      y: latitude,
      z: altitude * 1000
    };
  } catch (error) {
    return null;
  }
};

const trackFeatures = async (line1, line2) => {
  const trackFeatures = [];
  const coordinates = [];

  const satrec = twoline2satrec(
    line1,
    line2
  );

  // Calculate the orbital period in seconds
  const meanMotion = satrec.no; // Mean motion in radians per minute
  const orbitalPeriodMinutes = 2 * Math.PI / meanMotion; // Orbital period in minutes
  const orbitalPeriodSeconds = orbitalPeriodMinutes * 60; // Orbital period in seconds

  const currentTime = Date.now();

  for (let i = 0; i <= orbitalPeriodSeconds; i++) {
    let loc = null;
    try {
      loc = tleToGeographic(
        new Date(currentTime + i * 1200),
        line1,
        line2
      );
    } catch (error) { }

    if (loc !== null) {
      trackFeatures.push([loc.x, loc.y, loc.z]);

      coordinates.push({
        latitude: loc.y,
        longitude: loc.x,
      });
    }
  }

  const track = new Graphic({
    geometry: {
      type: "polyline", // autocasts as new Polyline()
      paths: [trackFeatures]
    },
    symbol: {
      type: "line-3d", // autocasts as new LineSymbol3D()
      symbolLayers: [
        {
          type: "line", // autocasts as new LineSymbol3DLayer()
          material: {
            color: [255, 255, 255, 1]
          },
          size: 2
        }
      ]
    }
  });

  return track;
};

const createGraphicSat = (line1, line2) => {
  const time = new Date();

  const { fullLaunchYear, launchNum, noradId } = getAttributes(line1);

  const satelliteLoc = tleToGeographic(time, line1, line2);

  const graphic = new Graphic({
    geometry: satelliteLoc,
    symbol: {
      type: "picture-marker", // autocasts as new PictureMarkerSymbol()
      url: IMAGES.satellite_icon,
      width: 48,
      height: 48
    },
    attributes: {
      // name: commonName,
      year: fullLaunchYear,
      id: noradId,
      number: launchNum,
      time: time,
      line1: line1,
      line2: line2
    },
  });

  return graphic;
};

const createGraphicUser = (latitude, longitude) => {
  const satelliteLoc = {
    type: "point", // Autocasts as new Point()
    x: longitude,
    y: latitude,
    z: 5000
  };

  const graphic = new Graphic({
    geometry: satelliteLoc,
    symbol: {
      type: "picture-marker", // autocasts as new PictureMarkerSymbol()
      url: LocationIconPNG,
      width: 48,
      height: 48
    }
  });

  return graphic;
};

const findNearestCoordinates = async (line1, line2, myLatitude, myLongitude) => {
  const coordinates = await listCoordinatesOfSat(line1, line2);

  const sortedCoordinates = coordinates.map(coord => {
    const { latitude, longitude } = coord;
    const distance = haversineDistance(myLatitude, myLongitude, latitude, longitude);
    return {
      latitude: coord.latitude,
      longitude: coord.longitude,
      altitude: coord.z,
      time: coord.time,
      distance: distance
    };
  }).sort((a, b) => a.distance - b.distance);

  // nearestCoordinates.push(sortedCoordinates[0]);
  // let lastTimes = [sortedCoordinates[0].time];

  // for (const coord of sortedCoordinates) {
  //   const { time } = coord;
  //   // Convert time to Date object
  //   const currentDate = new Date(time);
  //   const isNotSameDay = lastTimes.every(lastTime => !isSameDate(lastTime, currentDate));

  //   if (isNotSameDay) {
  //     lastTimes.push(currentDate);
  //     nearestCoordinates.push(coord);
  //   }

  //   if (nearestCoordinates.length === numNearest) {
  //     break;
  //   }
  // }

  // // Function to check if two dates are the same
  // function isSameDate(date1, date2) {
  //   return (
  //     date1.getFullYear() === date2.getFullYear() &&
  //     date1.getMonth() === date2.getMonth() &&
  //     date1.getDate() === date2.getDate()
  //   );
  // }

  // function compareByTime(a, b) {
  //   const timeA = new Date(a.time);
  //   const timeB = new Date(b.time);

  //   if (timeA < timeB) return -1;
  //   if (timeA > timeB) return 1;
  //   return 0;
  // }

  // nearestCoordinates.sort(compareByTime);
  // return nearestCoordinates;
  return sortedCoordinates[0];
};

const haversineDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371;
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
};

const listCoordinatesOfSat = async (line1, line2) => {
  const coordinates = [];
  // const currentTime = Date.now();
  // const seventyTwoHoursLater = currentTime + (72 * 60 * 60 * 1000);

  const currentTime = moment();
  const endOfDay = moment().endOf('day');

  for (let i = 0; i <= 100000; i++) {
    // const currentIterationTime = new Date(currentTime + i * 1000);
    const timeInMs = currentTime + (i * 1000);
    const currentIterationTime = moment(timeInMs).toDate();

    if (currentIterationTime < endOfDay) {
      try {
        const loc = tleToGeographic(
          currentIterationTime,
          line1,
          line2
        );
        coordinates.push({
          time: currentIterationTime,
          latitude: loc.y,
          longitude: loc.x,
          z: loc.z,
        });
      } catch (error) { }
    } else {
      break;
    }
  }

  return coordinates;
};

export {
  getAttributes,
  tleToGeographic,
  trackFeatures,
  createGraphicSat,
  createGraphicUser,
  findNearestCoordinates
};