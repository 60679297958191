import { LoadingButton } from "@mui/lab";
import { Box, FormControl, FormHelperText, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import * as Yup from "yup";

import authApi from '../../api/auth.api';
import StackCol from '../../components/common/StackCol';
import StackRow from '../../components/common/StackRow';
import { HeaderBox } from '../../components/common/HeaderBox';
import LANGUAGE from '../../utils/language.util';
import { routes } from "../../configs/routes.config";
import { TableRowInForm, TableRowInFormTwoTextField } from "../../components/common/TableRowCustom";
import { useSelector } from "react-redux";
import partnerApi from "../../api/partner.api";


const UserRegisterPage = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);

  const [isRegisterRequest, setIsRegisterRequest] = useState(false);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const getListPartners = async () => {
      const { response, err } = await partnerApi.list({ page: 1 });
      if (response) {
        setPartners(response.partners);
      }
      if (err) toast.error(err.message);
    };
    getListPartners();
  }, []);

  const form = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      postcode: "",
      prefecture: "",
      address: "",
      tel: "",
      password: "",
      password_confirm: "",
      role: "",
      partner_id: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.LAST_NAME)),
      last_name: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.NAME_ONLY)),
      email: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.EMAIL))
        .email(LANGUAGE.FIELD_INVALID(LANGUAGE.EMAIL)),
      password: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.PASSWORD)),
      address: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.ADDRESS_PREFECTURE_BELOW)),
      tel: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.TELEPHONE)),
    }),
    onSubmit: async values => {
      setIsRegisterRequest(true);

      const params = {
        first_name: values.last_name,
        last_name: values.first_name,
        user_email: values.email,
        postcode: values.postcode,
        prefecture: values.prefecture,
        address: values.address,
        tel: values.tel,
        user_password: values.password,
        user_role: values.role,
        partner_id: values.partner_id,
      };

      const { response, err } = await authApi.register(params);
      if (response) {
        form.resetForm();
        toast.success(LANGUAGE.REGISTERED);

        setTimeout(() => {
          navigate(routes.USERS);
        }, 2000);
      }

      if (err) {
        console.log(err);
        toast.error(err.message);
        setIsRegisterRequest(false);
      }
    }
  });


  return (
    <StackCol>
      <HeaderBox title={LANGUAGE.USER_MANAGEMENT} />
      <Box component="form" onSubmit={form.handleSubmit}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRowInFormTwoTextField
                title={LANGUAGE.NAME}
                idFirst="first_name"
                idSecond="last_name"
                labelFirst={LANGUAGE.LAST_NAME}
                labelSecond={LANGUAGE.NAME_ONLY}
                form={form}
              />
              {user && user.user_role === 1 &&
                <TableRow>
                  <TableCell align='right' sx={{ width: "30%" }}>
                    <Typography>
                      {LANGUAGE.PARTNER}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <FormControl>
                      <Select
                        id="partner_id"
                        name="partner_id"
                        value={form.values.partner_id}
                        onChange={form.handleChange}
                        size="small"
                        sx={{ width: "200px" }}
                        error={form.touched.partner_id && form.errors.partner_id !== undefined}
                        displayEmpty
                      >
                        <MenuItem
                          value=""
                        >
                          {LANGUAGE.SELECT}
                        </MenuItem>
                        {partners && partners.map((e, index) => (
                          <MenuItem
                            value={e.partner_id}
                            key={index}
                          >
                            {e.company_name}
                          </MenuItem>
                        ))}
                      </Select>
                      {form.touched.partner_id && form.errors.partner_id &&
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {form.errors.partner_id}
                        </FormHelperText>
                      }
                    </FormControl>
                  </TableCell>
                </TableRow>
              }
              <TableRow>
                <TableCell align='right' sx={{ width: "30%" }}>
                  <Typography>
                    {LANGUAGE.ROLE}
                  </Typography>
                </TableCell>
                <TableCell>
                  <FormControl>
                    <Select
                      id="role"
                      name="role"
                      value={form.values.role}
                      onChange={form.handleChange}
                      size="small"
                      sx={{ width: "200px" }}
                      error={form.touched.role && form.errors.role !== undefined}
                      displayEmpty
                    >
                      <MenuItem
                        value=""
                      >
                        {LANGUAGE.SELECT}
                      </MenuItem>
                      <MenuItem
                        value={1}
                        key={1}
                      >
                        {LANGUAGE.ADMIN}
                      </MenuItem>
                      <MenuItem
                        value={2}
                        key={2}
                      >
                        {LANGUAGE.MANAGER}
                      </MenuItem>
                      <MenuItem
                        value={3}
                        key={3}
                      >
                        {LANGUAGE.USER}
                      </MenuItem>
                    </Select>
                    {form.touched.role && form.errors.role &&
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {form.errors.role}
                      </FormHelperText>
                    }
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRowInForm
                title={LANGUAGE.POST_CODE}
                id="postcode"
                form={form}
                sx={{ width: "215px", pr: 2 }}
              />
              <TableRowInForm
                title={LANGUAGE.PREFECTURE}
                id="prefecture"
                form={form}
              />
              <TableRowInForm
                title={LANGUAGE.ADDRESS_PREFECTURE_BELOW}
                id="address"
                form={form}
              />
              <TableRowInForm
                title={LANGUAGE.TELEPHONE}
                id="tel"
                form={form}
              />
              <TableRowInForm
                title={LANGUAGE.EMAIL}
                id="email"
                form={form}
              />
              <TableRowInForm
                title={LANGUAGE.PASSWORD}
                id="password"
                form={form}
              />
            </TableBody>
          </Table>
        </TableContainer>

      </Box>
      <StackRow justifyContent={"center"}>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={isRegisterRequest}
          onClick={form.handleSubmit}
          size='large'
        >
          {LANGUAGE.REGISTER}
        </LoadingButton>
      </StackRow>
    </StackCol>
  );
};

export default UserRegisterPage;