import {
  Button, Pagination, Paper, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TableRow
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import partnerApi from '../../api/partner.api.js';
import { HeaderBoxWithButton } from '../../components/common/HeaderBox.jsx';
import StackCol from '../../components/common/StackCol';
import StackRow from '../../components/common/StackRow.jsx';
import { routes, routesGen } from '../../configs/routes.config';
import LANGUAGE from '../../utils/language.util.js';

const PartnerPage = () => {
  const [partners, setPartners] = useState([]);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState({ page: 1 });
  const [pageCount, setPageCount] = useState(1);
  const [getParamSuccess, setGetParamSuccess] = useState(false);

  useEffect(() => {
    getParams();
  }, []);

  useEffect(() => {
    const getListPartners = async () => {
      const { response, err } = await partnerApi.list(query);
      if (response) {
        setPartners(response.partners);
        setPageCount(Math.ceil(response.count / 10));
      }
      if (err) toast.error(err.message);
    };
    if (getParamSuccess) {
      getListPartners();
    }
  }, [query, getParamSuccess]);

  const handleChange = (event, value) => {
    setPage(value);
    setQuery({ page: value });
    addParams(value);
  };

  const addParams = (page) => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("page", page);
    window.history.pushState({ path: currentUrl.href }, "", currentUrl.href);
  };

  const getParams = () => {
    const url = new URL(window.location.href);
    const pageParam = url.searchParams.get("page");
    if (pageParam !== null) {
      const page = parseInt(pageParam, 10);
      setPage(page);
      setQuery({ page: page });
    }
    setGetParamSuccess(true);
  };

  return (
    <StackCol>
      <HeaderBoxWithButton
        title={LANGUAGE.PARTNER_MANAGEMENT}
        titleButton={LANGUAGE.PARTNER_ADD}
        to={routes.PARTNER_CREATE}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>{LANGUAGE.SHORT_ID}</TableCell>
              <TableCell>{LANGUAGE.COMPANY_NAME}</TableCell>
              <TableCell>{LANGUAGE.REPRESENTATIVE_NAME}</TableCell>
              <TableCell>{LANGUAGE.PREFECTURE}</TableCell>
              <TableCell>{LANGUAGE.TELEPHONE}</TableCell>
              <TableCell sx={{ width: 100 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partners && partners.map((partner, index) => (
              <TableRow key={index}>
                <TableCell>{partner.short_id}</TableCell>
                <TableCell>{partner.company_name}</TableCell>
                <TableCell>{partner.first_name + " " + partner.last_name}</TableCell>
                <TableCell>{partner.prefecture}</TableCell>
                <TableCell>{partner.tel}</TableCell>
                <TableCell>
                  <Button
                    color="primary"
                    component={Link}
                    to={routesGen.partnerDetail(partner.partner_id)}
                  >
                    {LANGUAGE.DETAIL}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <StackRow spacing={2}>
        <Pagination
          variant="outlined"
          shape="rounded"
          color="primary"
          count={pageCount}
          page={page}
          onChange={handleChange}
        />
      </StackRow>
    </StackCol>
  );
};

export default PartnerPage;