import { colors } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';


export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: colors.blueGrey[50],
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const MainColor = "#1976d2";

export const MainColor1 = "#3f7ec1";

export const MenuActiveColor = colors.grey[200];

export const BtnDownLoadColor = "#C07E15";

export const BtnDeleteColor = "#D87568";

export const BtnNFTRequestColor = "#15C071";

export const BackgroundGrey = "#EEEEEE";

export const BtnUploadColor = "#4497D7";

export const BtnCheckCertificateColor = "gray";

export const BtnSATShowColor = "#71a788";

