export const routes = {
  HOME: "/",
  /* User */
  SIGNIN: "/signin",
  SIGN_UP: "/signup",
  USERS: "/users",
  USER_DETAIL: "/users/:id",
  USER_REGISTER: "/users/register",
  USER_UPDATE: "/users/:id/update",
  /* Material */
  MATERIALS: "/materials",
  CERTIFICATE: "/certificate",
  CERTIFICATE_DETAIL: "/certificate/:id",
  CERTIFICATE_CHECK: "/certificate/:id/check",
  MATERIAL_CREATE: "/materials/create",
  MATERIAL_DETAIL: "/materials/:id",
  MATERIAL_UPDATE: "/materials/:id/update",
  MATERIAL_CERTIFICATE: "/materials/:id/certificate",
  MATERIAL_SAT: "/materials/:id/satellite",
  /* Prompt */
  PROMPTS: "/prompts",
  PROMPT_CREATE: "/prompts/create",
  /* Partner */
  PARTNERS: "/partners",
  PARTNER_DETAIL: "/partners/:id",
  PARTNER_CREATE: "/partners/create",
  PARTNER_UPDATE: "/partners/:id/update",
  /* Setting */
  SETTING: "/setting",
  PROFILE: "/setting/profile",
  PROFILE_EDIT: "/setting/profile/edit",
  PASSWORD_CHANGE: "/setting/password",
  /* Password */
  PASSWORD_FORGOT: "/password/forgot",
  PASSWORD_RESET: "/password/reset/:token",
  /* SAT */
  SATELLITES: "/satellites",
  SATELLITE_DETAIL: "/satellites/:id",
};

export const routesGen = {
  userDetail: (id) => `/users/${id}`,
  userUpdate: (id) => `/users/${id}/update`,
  partnerDetail: (id) => `/partners/${id}`,
  partnerUpdate: (id) => `/partners/${id}/update`,
  materialDetail: (id) => `/materials/${id}`,
  materialUpdate: (id) => `/materials/${id}/update`,
  materialCertificate: (id) => `/materials/${id}/certificate`,
  materialSat: (id) => `/materials/${id}/satellite`,
  passwordReset: (token) => `/password/reset/${token}`,
  satelliteDetail: (id) => `/satellites/${id}`,
  certificateDetail: (id) => `/certificate/${id}`,
};