import { createBrowserRouter } from "react-router-dom";
import Page from "../components/common/Page";
import AppLayout from "../components/layout/AppLayout";
import AuthLayout from "../components/layout/AuthLayout";
import HomeLayout from "../components/layout/HomeLayout";
import MainLayout from "../components/layout/MainLayout";
import MaterialLayout from "../components/layout/MaterialLayout";
import SettingLayout from "../components/layout/SettingLayout";
import UserLayout from "../components/layout/UserLayout";
import { routes } from "../configs/routes.config";
import DashboardPage from "../pages/DashboardPage";
import LoginPage from "../pages/LoginPage";
import MaterialCreatePage from "../pages/Material/MaterialCreatePage";
import MaterialDetailPage from "../pages/Material/MaterialDetailPage";
import MaterialPage from "../pages/Material/MaterialPage";
import MaterialUpdatePage from "../pages/Material/MaterialUpdatePage";
import NotFound from '../pages/NotFound';
import PartnerCreatePage from "../pages/Partner/PartnerCreatePage";
import PartnerDetailPage from "../pages/Partner/PartnerDetailPage";
import PartnerPage from "../pages/Partner/PartnerPage";
import PartnerUpdatePage from "../pages/Partner/PartnerUpdatePage";
import PasswordForgotPage from "../pages/Password/PasswordForgotPage";
import PasswordResetPage from "../pages/Password/PasswordResetPage";
import SignUpPage from "../pages/SignUpPage";
import ProfileEditPage from "../pages/User/ProfileEditPage";
import UserDetailPage from '../pages/User/UserDetailPage';
import UserPage from "../pages/User/UserPage";
import UserUpdatePage from "../pages/User/UserUpdatePage";
import PartnerLayout from './../components/layout/PartnerLayout';
import { APP_STATE, ROLES } from './../configs/constants.config';
import ProfilePage from './../pages/User/ProfilePage';
import PasswordChangePage from "../pages/Password/PasswordChangePage";
import LANGUAGE from "../utils/language.util";
import CertificateCheck from "../pages/Material/CertificateCheck";
import SatellitePage from "../pages/Material/SatellitePage";
import MaterialUserLayout from "../components/layout/MaterialUserLayout";
import MaterialUserPage from "../pages/MaterialUser/MaterialUserPage";
import UserRegisterPage from './../pages/User/UserRegisterPage';


export const router = createBrowserRouter([
  {
    path: routes.HOME,
    element: <AppLayout />,
    children: [
      /* Home */
      {
        path: routes.HOME,
        element: <Page
          state={APP_STATE.DASHBOARD}
          element={<DashboardPage />}
          roles={ROLES.ALL}
        />
      },
      {
        path: "*",
        element: <NotFound />
      },
      {
        path: routes.SIGNIN,
        element: <LoginPage />
      },
      {
        element: <Page element={<MaterialUserLayout />}
        />,
        children: [
          {
            path: routes.CERTIFICATE_DETAIL,
            element: <MaterialUserPage />
          },
        ]
      },
      {
        element:
          <Page
            element={<AuthLayout />}
          />,
        children: [
          {
            path: routes.PASSWORD_FORGOT,
            element: <PasswordForgotPage />
          },
          {
            path: routes.PASSWORD_RESET,
            element: <PasswordResetPage />
          },
          {
            path: routes.SIGN_UP,
            element: <SignUpPage />
          },
        ]
      },
      {
        element:
          <Page
            element={<MainLayout />}
          />,
        children: [
          {
            element: <Page
              element={<HomeLayout />}
              breadcrumb={{
                displayText: LANGUAGE.MANAGEMENT_SCREEN,
                level: 0,
                link: routes.HOME
              }}
            />,
            children: [
              /* Setting */
              {
                path: routes.SETTING,
                element: <Page
                  state={APP_STATE.SETTING}
                  element={<SettingLayout />}
                  roles={ROLES.ALL}
                  breadcrumb={{
                    displayText: "設定",
                    level: 1,
                    link: routes.PROFILE
                  }}
                />,
                children: [
                  {
                    path: routes.PROFILE,
                    element: <Page
                      state={APP_STATE.SETTING}
                      element={<ProfilePage />}
                      roles={ROLES.ALL}
                      breadcrumb={{
                        displayText: "プロフィール",
                        level: 2,
                        link: routes.PROFILE
                      }}
                    />,
                  },
                  {
                    path: routes.PROFILE_EDIT,
                    element: <Page
                      state={APP_STATE.SETTING}
                      element={<ProfileEditPage />}
                      roles={ROLES.ALL}
                      breadcrumb={{
                        displayText: "プロフィール",
                        level: 2,
                        link: routes.PROFILE
                      }}
                    />,
                  },
                  {
                    path: routes.PASSWORD_CHANGE,
                    element: <Page
                      state={APP_STATE.SETTING}
                      element={<PasswordChangePage />}
                      roles={ROLES.ALL}
                      breadcrumb={{
                        displayText: "パスワード",
                        level: 2,
                        link: routes.PASSWORD_CHANGE
                      }}
                    />,
                  },
                ]
              },
              /* Partner */
              {
                path: routes.PARTNERS,
                element: <Page
                  state={APP_STATE.PARTNER}
                  element={<PartnerLayout />}
                  roles={ROLES.ADMIN}
                  breadcrumb={{
                    displayText: "代理店",
                    level: 1,
                    link: routes.PARTNERS
                  }}
                />,
                children: [
                  {
                    path: routes.PARTNERS,
                    element: <Page
                      state={APP_STATE.PARTNER}
                      element={<PartnerPage />}
                      roles={ROLES.ADMIN}
                    />
                  },
                  {
                    path: routes.PARTNER_CREATE,
                    element: <Page
                      state={APP_STATE.PARTNER}
                      element={<PartnerCreatePage />}
                      roles={ROLES.ADMIN}
                      breadcrumb={{
                        displayText: "登録",
                        level: 2,
                        link: routes.PARTNER_CREATE
                      }}
                    />
                  },
                  {
                    path: routes.PARTNER_DETAIL,
                    element: <Page
                      state={APP_STATE.PARTNER}
                      element={<PartnerDetailPage />}
                      roles={ROLES.ADMIN}
                    />
                  },
                  {
                    path: routes.PARTNER_UPDATE,
                    element: <Page
                      state={APP_STATE.PARTNER}
                      element={<PartnerUpdatePage />}
                      roles={ROLES.ADMIN}
                    />
                  },
                ]
              },
              /* User */
              {
                path: routes.USERS,
                element: <Page
                  state={APP_STATE.USER}
                  element={<UserLayout />}
                  roles={ROLES.MANAGER}
                  breadcrumb={{
                    displayText: "ユーザー",
                    level: 1,
                    link: routes.USERS
                  }}
                />,
                children: [
                  {
                    path: routes.USERS,
                    element: <Page
                      element={<UserPage />}
                    />
                  },
                  {
                    path: routes.USER_REGISTER,
                    element: <Page
                      element={<UserRegisterPage />}
                      roles={ROLES.MANAGER}
                    />,
                  },
                  {
                    path: routes.USER_DETAIL,
                    element: <Page
                      element={<UserDetailPage />}
                    />,
                  },
                  {
                    path: routes.USER_UPDATE,
                    element: <Page
                      element={<UserUpdatePage />}
                      roles={ROLES.ADMIN}
                    />,
                  }
                ]
              },
              /* Material */
              {
                path: routes.MATERIALS,
                element: <Page
                  state={APP_STATE.MATERIAL}
                  element={<MaterialLayout />}
                  roles={ROLES.ALL}
                  breadcrumb={{
                    displayText: LANGUAGE.REGISTRATION_INFORMATION,
                    level: 1,
                    link: routes.MATERIALS
                  }}
                />,
                children: [
                  {
                    path: routes.MATERIALS,
                    element: <Page
                      state={APP_STATE.MATERIAL}
                      element={<MaterialPage />}
                      roles={ROLES.ALL}
                    />
                  },
                  {
                    path: routes.MATERIAL_DETAIL,
                    element: <Page
                      state={APP_STATE.MATERIAL}
                      element={<MaterialDetailPage />}
                      roles={ROLES.ALL}
                    />,
                  },
                  {
                    path: routes.MATERIAL_CREATE,
                    element: <Page
                      state={APP_STATE.MATERIAL}
                      element={<MaterialCreatePage />}
                      roles={ROLES.ADMIN}
                    />,
                  },
                  {
                    path: routes.MATERIAL_UPDATE,
                    element: <Page
                      state={APP_STATE.MATERIAL}
                      element={<MaterialUpdatePage />}
                      roles={ROLES.ALL}
                    />,
                  },
                  {
                    path: routes.MATERIAL_CERTIFICATE,
                    element: <Page
                      state={APP_STATE.MATERIAL}
                      element={<CertificateCheck />}
                      roles={ROLES.ALL}
                    />,
                  },
                  {
                    path: routes.MATERIAL_SAT,
                    element: <Page
                      state={APP_STATE.MATERIAL}
                      element={<SatellitePage />}
                      roles={ROLES.ALL}
                    />,
                  },
                ]
              },
            ]
          }
        ]
      },
    ]
  }
]);