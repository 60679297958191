import { Button, Paper, Stack, Table, TableBody, TableContainer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

import userApi from '../../api/user.api';
import { HeaderBox } from '../../components/common/HeaderBox';
import StackCol from '../../components/common/StackCol';
import StackRow from '../../components/common/StackRow';
import { TableRowTwoText } from '../../components/common/TableRowCustom';
import { routes } from '../../configs/routes.config';
import { formattedDate } from '../../utils/date.utils';
import LANGUAGE from '../../utils/language.util';


const ProfilePage = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState();

  useEffect(() => {
    const getUser = async () => {
      const { response, err } = await userApi.profile();
      setUser(response);

      if (err) toast.error(err.message);
      if (response) setUser(response);
    };
    getUser();
  }, []);

  useEffect(() => {
    if (user) {
      console.log(user);
    }
  }, [user]);

  const onClickEdit = async () => {
    navigate(routes.PROFILE_EDIT);
  };

  return (
    <StackCol>
      <HeaderBox
        title={LANGUAGE.CUSTOMER_INFORMATION}
      />
      {user &&
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRowTwoText
                title={LANGUAGE.APPLICANT_NAME}
                value={user.display_name}
              />
              <TableRowTwoText
                title={LANGUAGE.POST_CODE}
                value={user.postcode}
              />
              <TableRowTwoText
                title={LANGUAGE.PREFECTURE}
                value={user.prefecture}
              />
              <TableRowTwoText
                title={LANGUAGE.ADDRESS_PREFECTURE_BELOW}
                value={user.address}
              />
              <TableRowTwoText
                title={LANGUAGE.BUILDING_NAME}
                value={user.building}
              />
              <TableRowTwoText
                title={LANGUAGE.TELEPHONE}
                value={user.tel}
              />
              <TableRowTwoText
                title={LANGUAGE.EMAIL}
                value={user.user_email}
              />
              <TableRowTwoText
                title={LANGUAGE.CREATED_DATE}
                value={formattedDate(user.created_date)}
              />
              <TableRowTwoText
                title={LANGUAGE.UPDATED_DATE}
                value={formattedDate(user.updated_date)}
              />
            </TableBody>
          </Table>
        </TableContainer>
      }
      <StackRow>
        <Stack sx={{ width: "30%", alignItems: "end" }}>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Button
            variant="contained"
            sx={{ width: "100px" }}
            onClick={onClickEdit}
          >
            {LANGUAGE.EDIT}
          </Button>
        </Stack>
      </StackRow>
    </StackCol>
  );
};

export default ProfilePage;