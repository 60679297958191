import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, FormControlLabel, FormGroup, Link, Paper, Table, TableBody, TableContainer, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import authApi from '../api/auth.api';
import StackCol from '../components/common/StackCol';
import StackRow from '../components/common/StackRow';
import { TableRowInForm, TableRowInFormTwoTextField, TableRowWithPasswordField } from '../components/common/TableRowCustom';
import { routes } from '../configs/routes.config';
import LANGUAGE from '../utils/language.util';

const SignUpPage = () => {
  const navigate = useNavigate();

  const [isSignUpRequest, setIsSignUpRequest] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [isSignUpSuccess, setIsSignUpSuccess] = useState(false);
  const [isAgreeChecked, setIsAgreeChecked] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const togglePasswordConfirmVisibility = () => {
    setShowPasswordConfirm((prevShowPassword) => !prevShowPassword);
  };

  const form = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      postcode: "",
      prefecture: "",
      address: "",
      tel: "",
      password: "",
      password_confirm: "",
      partner_id: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.LAST_NAME)),
      last_name: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.NAME_ONLY)),
      email: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.EMAIL))
        .email(LANGUAGE.FIELD_INVALID(LANGUAGE.EMAIL)),
      password: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.PASSWORD)),
      password_confirm: Yup.string()
        .oneOf([Yup.ref('password'), null], LANGUAGE.PASSWORD_MUST_MATCH)
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.PASSWORD_CONFIRM))
    }),
    onSubmit: async values => {
      setIsSignUpRequest(true);
      setIsSignUpSuccess(false);
      const params = {
        first_name: values.last_name,
        last_name: values.first_name,
        user_email: values.email,
        postcode: values.postcode,
        prefecture: values.prefecture,
        address: values.address,
        tel: values.tel,
        user_password: values.password,
        user_password_repeat: values.password_confirm,
        partner_id: values.partner_id,
      };

      const { response, err } = await authApi.signup(params);
      if (response) {
        form.resetForm();
        toast.success(LANGUAGE.REGISTERED);
        setIsSignUpSuccess(true);
        setIsSignUpRequest(false);

        setTimeout(() => {
          navigate(routes.SIGNIN);
        }, 2000);
      }

      if (err) {
        console.log(err);
        toast.error(err.message);
        setIsSignUpRequest(false);
      }
    }
  });

  const handleAgreeChange = () => {
    setIsAgreeChecked(!isAgreeChecked);
  };

  return (
    <StackCol sx={{ p: 5 }} alignItems="center">
      <Box component="form" onSubmit={form.handleSubmit} sx={{ width: "960px" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRowInFormTwoTextField
                title={LANGUAGE.NAME}
                idFirst="first_name"
                idSecond="last_name"
                labelFirst={LANGUAGE.LAST_NAME}
                labelSecond={LANGUAGE.NAME_ONLY}
                form={form}
              ></TableRowInFormTwoTextField>
              <TableRowInForm
                title={LANGUAGE.POST_CODE}
                id="postcode"
                form={form}
                sx={{ width: "50%", pr: 2 }}
              ></TableRowInForm>
              <TableRowInForm
                title={LANGUAGE.PREFECTURE}
                id="prefecture"
                form={form}
              ></TableRowInForm>
              <TableRowInForm
                title={LANGUAGE.ADDRESS_PREFECTURE_BELOW}
                id="address"
                form={form}
              ></TableRowInForm>
              <TableRowInForm
                title={LANGUAGE.TELEPHONE}
                id="tel"
                form={form}
              ></TableRowInForm>
              <TableRowInForm
                title={LANGUAGE.SHORT_ID}
                id="partner_id"
                form={form}
              ></TableRowInForm>
              <TableRowInForm
                title={LANGUAGE.EMAIL}
                id="email"
                form={form}
              ></TableRowInForm>
              <TableRowWithPasswordField
                title={LANGUAGE.PASSWORD}
                id="password"
                form={form}
                togglePasswordVisibility={togglePasswordVisibility}
                showPassword={showPassword}
              ></TableRowWithPasswordField>
              <TableRowWithPasswordField
                title={LANGUAGE.PASSWORD_CONFIRM}
                id="password_confirm"
                form={form}
                togglePasswordVisibility={togglePasswordConfirmVisibility}
                showPassword={showPasswordConfirm}
              ></TableRowWithPasswordField>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <StackCol alignItems="center">
        <Typography>
          新規会員登録の際は「
          <Link
            underline="none"
            href="#"
          >
            {LANGUAGE.TERMS_OF_USE}
          </Link>
          」「
          <Link
            href="#"
            underline="none"
          >
            {LANGUAGE.PRIVACY_POLICY}
          </Link>
          」をご確認いただき、
          <br />
          同意の上、会員登録へお進みください。
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox
              checked={isAgreeChecked}
              onChange={handleAgreeChange}
            />
            }
            label={LANGUAGE.AGREE}
            style={{
              userSelect: "none"
            }}
          />
        </FormGroup>
      </StackCol>
      <StackRow justifyContent={"center"}>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={isSignUpRequest}
          onClick={form.handleSubmit}
          disabled={!isAgreeChecked}
          size='large'
        >
          {LANGUAGE.REGISTER}
        </LoadingButton>
      </StackRow>
      {isSignUpSuccess &&
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 4,
          }}>
          <Typography sx={{
            color: "green",
            align: "justify"
          }}>
            {LANGUAGE.SIGN_UP_SUCCESS}
          </Typography>
        </Box>
      }
    </StackCol>
  );
};

export default SignUpPage;