import {
  Box,
  Drawer, List, ListItem, ListItemButton, ListItemIcon,
  ListItemText, Stack, Typography, colors
} from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import menuConfigs from "../../configs/menu.configs";
import { MenuActiveColor } from "../../styles/custom.style";
import Animate from './Animate';
import Logo from "./Logo";


const Sidebar = ({ sidebarWidth }) => {
  const { user } = useSelector((state) => state.user);
  const { appState } = useSelector((state) => state.appState);

  const MenuItem = (props) => {
    return (
      <ListItem key={props.index} sx={{ py: 0.5 }}>
        <ListItemButton sx={{
          borderRadius: "10px",
          bgcolor: props.isActive ? MenuActiveColor : "",
          color: props.isActive ? colors.common.black : "",
          "&:hover": {
            bgcolor: props.isActive ? MenuActiveColor : "",
            color: props.isActive ? colors.common.black : "",
          },
          height: 60,
        }}
          component={Link}
          to={props.item.path}
        >
          <ListItemIcon sx={{
            minWidth: "40px",
          }}>
            {props.item.icon}
          </ListItemIcon>
          <ListItemText primary={
            <Typography fontWeight={600}>
              {props.item.title(props.userRole)}
            </Typography>
          } />
        </ListItemButton>
      </ListItem >
    );
  };

  const drawer = (
    <Box
      padding={3}
      paddingBottom={0}
      display="flex"
      flexDirection="column"
      height="100vh"
      sx={{
        "::-webkit-scrollbar": {
          display: "none"
        },
        backgroundColor: "white",
      }}
    >
      {/* logo */}
      <Box sx={{ textAlign: "center", mb: 2 }}>
        <Animate type="fade" delay={1}>
          <Logo></Logo>
        </Animate>
      </Box>
      {/* logo */}
      <Animate sx={{ flexGrow: 1 }}>
        <Stack height='100%' justifyContent='space-between'>
          <Box>
            {/* menu group 1 */}
            <List>
              {menuConfigs.main.map((item, index) => (
                (user && item.roles.includes(user.user_role)) &&
                <MenuItem
                  key={index}
                  item={item}
                  isActive={item.state === appState}
                  userRole={user.user_role}
                />
              ))}
            </List>
            {/* menu group 1 */}
          </Box>
        </Stack >
      </Animate >
    </Box >
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { md: sidebarWidth },
        flexShrink: { md: 0 }
      }}
    >
      {/* large screen */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: sidebarWidth,
            borderWidth: 0,
            bgcolor: "transparent",
            "::-webkit-scrollbar": {
              display: "none"
            }
          }
        }}
        open
      >
        {drawer}
      </Drawer>
      {/* large screen */}
    </Box>
  );
};

export default Sidebar;