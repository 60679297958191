import { LoadingButton } from "@mui/lab";
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { AuthorizeHeader, baseUrl } from '../../api/axios.client';
import { materialEndpoints } from '../../api/material.api';
import { BoxUploadImage } from "../../components/common/BoxUploadFile";
import { HeaderBox } from "../../components/common/HeaderBox";
import MPaper from '../../components/common/MPaper';
import StackCol from '../../components/common/StackCol';
import StackRow from '../../components/common/StackRow';
import { TextFieldCustom } from "../../components/common/TextFieldCustom";
import LANGUAGE from '../../utils/language.util';
import userApi from './../../api/user.api';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const MaterialCreatePage = () => {
  const navigate = useNavigate();

  const [file, setFile] = useState(null);
  const [isRegisterRequest, setIsRegisterRequest] = useState(false);
  const [users, setUsers] = useState([]);


  useEffect(() => {
    const getListUsers = async () => {
      const { response, err } = await userApi.list({ page: 1 });
      if (response) {
        setUsers(response.users);
      }
      if (err) toast.error(err.message);
    };
    getListUsers();
  }, []);

  const form = useFormik({
    initialValues: {
      nick_name: "",
      user_id: "",
      message: "",
      monthly_memorial_date: "",
      email_notification_1: "",
      email_notification_2: "",
      email_notification_3: "",
      email_notification_4: "",
    },
    validationSchema: Yup.object({
      nick_name: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.TARGET_PERSON_NAME)),
      user_id: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.USER)),
      monthly_memorial_date: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.MONTHLY_MEMORIAL_DATE)),
      email_notification_1: Yup.string().trim().nullable()
        .email(LANGUAGE.FIELD_INVALID(LANGUAGE.EMAIL)),
      email_notification_2: Yup.string().trim().nullable()
        .email(LANGUAGE.FIELD_INVALID(LANGUAGE.EMAIL)),
      email_notification_3: Yup.string().trim().nullable()
        .email(LANGUAGE.FIELD_INVALID(LANGUAGE.EMAIL)),
      email_notification_4: Yup.string().trim().nullable()
        .email(LANGUAGE.FIELD_INVALID(LANGUAGE.EMAIL)),
    }),
    onSubmit: async values => {
      setIsRegisterRequest(true);

      const formData = new FormData();
      formData.append('nickname', values.nick_name);
      formData.append('user_id', values.user_id);
      formData.append('message', values.message);
      formData.append('monthly_memorial_date', values.monthly_memorial_date);
      formData.append('email_notification_1', values.email_notification_1);
      formData.append('email_notification_2', values.email_notification_2);
      formData.append('email_notification_3', values.email_notification_3);
      formData.append('email_notification_4', values.email_notification_4);

      if (file) {
        formData.append('material', file);
      }

      axios({
        method: "post",
        url: baseUrl + materialEndpoints.create,
        data: formData,
        headers: AuthorizeHeader(),
      })
        .then(function (response) {
          form.resetForm();
          toast.success(LANGUAGE.REGISTERED);
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        })
        .catch(function (error) {
          if (error && error.data) {
            toast.error(error.data.message);
          } else {
            toast.error(error.response.data.message);
          }
          setIsRegisterRequest(false);
        });
    }
  });

  // Function to handle file input change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const handleDateChange = (newDate) => {
    try {
      const isoTimestamp = newDate.toISOString();
      form.setFieldValue('monthly_memorial_date', isoTimestamp);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StackCol>
      <HeaderBox title={LANGUAGE.CERTIFICATE} />
      <StackCol sx={{
        alignItems: "center",
      }}>
        <MPaper sx={{
          minWidth: "300px",
          maxWidth: "100%",
        }}>
          <Box
            component="form"
            onSubmit={form.handleSubmit}
            sx={{
              display: "flex",
              justifyContent: "center"
            }}>
            <StackCol sx={{ width: "700px" }}>
              <TextFieldCustom
                id={"nick_name"}
                name={"nick_name"}
                form={form}
                label={LANGUAGE.TARGET_PERSON_NAME}
              />
              <FormControl>
                <InputLabel id="user-id-label">{LANGUAGE.USER}</InputLabel>
                <Select
                  labelId="user-id-label"
                  label={LANGUAGE.USER}
                  id="user_id"
                  name="user_id"
                  value={form.values.user_id}
                  onChange={form.handleChange}
                  error={form.touched.user_id && form.errors.user_id !== undefined}
                >
                  {users && users.map((e, index) => (
                    <MenuItem
                      value={e.user_id}
                      key={index}
                    >
                      {e.display_name}
                    </MenuItem>
                  ))}
                </Select>
                {form.touched.user_id && form.errors.user_id &&
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {form.errors.user_id}
                  </FormHelperText>
                }
              </FormControl>
              <TextFieldCustom
                id={"message"}
                name={"message"}
                form={form}
                label={LANGUAGE.MESSAGE}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl>
                  <DatePicker
                    label={LANGUAGE.MONTHLY_MEMORIAL_DATE}
                    value={form.values.monthly_memorial_date ? dayjs(form.values.monthly_memorial_date) : null}
                    onChange={(newValue) => handleDateChange(newValue)}
                    format="YYYY/MM/DD"
                  />
                  {form.touched.monthly_memorial_date && form.errors.monthly_memorial_date &&
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      {form.errors.monthly_memorial_date}
                    </FormHelperText>
                  }
                </FormControl>
              </LocalizationProvider>
              <TextFieldCustom
                id={"email_notification_1"}
                name={"email_notification_1"}
                form={form}
                label={LANGUAGE.EMAIL_NOTIFICATION_1}
              />
              <TextFieldCustom
                id={"email_notification_2"}
                name={"email_notification_2"}
                form={form}
                label={LANGUAGE.EMAIL_NOTIFICATION_2}
              />
              <TextFieldCustom
                id={"email_notification_3"}
                name={"email_notification_3"}
                form={form}
                label={LANGUAGE.EMAIL_NOTIFICATION_3}
              />
              <TextFieldCustom
                id={"email_notification_4"}
                name={"email_notification_4"}
                form={form}
                label={LANGUAGE.EMAIL_NOTIFICATION_4}
              />
              <BoxUploadImage
                title={LANGUAGE.IMAGE_UPLOAD}
                file={file}
                handleFileChange={handleFileChange}
                accept=".jpg, .png, .jpeg"
              />
            </StackCol>
          </Box>
        </MPaper>
      </StackCol>
      <StackRow justifyContent={"center"}>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={isRegisterRequest}
          onClick={form.handleSubmit}
        >
          {LANGUAGE.REGISTER}
        </LoadingButton>
      </StackRow>
    </StackCol>
  );
};

export default MaterialCreatePage;