import { pdf } from '@react-pdf/renderer';
import axios from 'axios';
import JSZip from 'jszip';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthorizeHeader, baseUrl } from '../../api/axios.client';
import materialApi, { materialEndpoints } from '../../api/material.api';
import { routes, routesGen } from '../../configs/routes.config';
import { addBreadcrumb, removeBreadcrumbAtLevel } from '../../redux/features/app.state.slice';
import LANGUAGE from '../../utils/language.util';
import CertificatePDF from './CertificatePDF';
import MaterialDetailWebPage from './MaterialDetailWebPage';
import MaterialDetailMobilePage from './MaterialDetailMobilePage';
import { getDetailTimeFromDate } from '../../utils/date.utils';


const MaterialDetailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const { user } = useSelector((state) => state.user);

  const [material, setMaterial] = useState();
  const [sentMessage, setSentMessage] = useState("");
  const [sizeImage, setSizeImage] = useState({
    hasSize: false,
    height: "100%",
    width: "100%"
  });
  const [isCertificateMaking, setIsCertificateMaking] = useState(false);
  const [isDownloadingCertificate, setIsDownloadingCertificate] = useState(false);
  const [isDownloadingData, setIsDownloadingData] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1200);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (material) {
      if (material.trace_json_url) {
        const detailTime = getDetailTimeFromDate(material.updated_date);
        const sentMessage = `送信日時  ${detailTime.year} 年 ${detailTime.month} 月 ${detailTime.day} 日 ${detailTime.hour} 時 ${detailTime.minute} 分`;
        setSentMessage(sentMessage);
      }
    }
  }, [material]);

  useEffect(() => {
    const getMaterial = async () => {
      const { response, err } = await materialApi.getOne(id);

      if (err) toast.error(err.message);
      if (response) {
        setMaterial(response);
      };
    };
    getMaterial();
  }, [id]);

  useEffect(() => {
    if (material && material.material_image_link) {
      const img = new Image();
      img.src = material.material_image_link;
      img.onload = () => {
        if (img.width > img.height) {
          setTimeout(() => {
            setSizeImage({
              hasSize: true,
              width: "60%",
              height: "auto"
            });
          }, 500);
        } else {
          setTimeout(() => {
            setSizeImage({
              hasSize: true,
              width: "auto",
              height: "65%"
            });
          }, 500);
        }
      };
    }
  }, [material]);

  useEffect(() => {
    dispatch(addBreadcrumb({
      displayText: id,
      level: 3,
      link: routesGen.materialDetail(id)
    }));
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(removeBreadcrumbAtLevel({ level: 3 }));
    };
  }, [dispatch]);

  const onClickEdit = async () => {
    navigate(routesGen.materialUpdate(id));
  };

  const onClickReturn = async () => {
    navigate(-1);
  };

  const onClickDownloadData = async () => {
    if (material) {
      const zip = new JSZip();
      setIsDownloadingData(true);

      // Add text file to the zip
      const textFile = new Blob([material.message], { type: 'text/plain' });
      zip.file(`${id}.txt`, textFile);

      if (material.material_image_link) {
        const getFileExtension = (url) => url.split('/').pop().split('.').pop();
        const fileExtension = getFileExtension(material.material_image_link);
        try {
          const response = await materialApi.getImage(id);

          // Add compressed image to the zip
          zip.file(`${material.material_id}.${fileExtension}`, response.response, { binary: true });

          // Create a Blob from the zip content
          const zipBlob = await zip.generateAsync({ type: 'blob' });

          // Create a link for downloading the zip file
          const zipLink = document.createElement('a');
          zipLink.href = URL.createObjectURL(zipBlob);
          zipLink.download = `${id}.zip`;

          // Append the link to the document and trigger a click event
          document.body.appendChild(zipLink);
          zipLink.click();

          // Remove the link from the document
          document.body.removeChild(zipLink);
          setIsDownloadingData(false);
        } catch (error) {
          setIsDownloadingData(false);
          console.error('Error downloading file:', error);
        }
      }
    }
  };

  const onClickCertificateIssue = async () => {
    const getMaterial = async () => {
      const { response, err } = await materialApi.getOne(id);

      if (err) toast.error(err.message);
      if (response) {
        setMaterial(response);
      };
    };

    const createPDF = async (imageBuffer, imageExt) => {
      const dataMakePDF = {
        nickname: material.nickname,
        username: material.user.display_name,
        id: material.material_id,
        sentMessage: sentMessage,
        imageBuffer: imageBuffer,
        imageExt: imageExt,
        sizeImage: sizeImage
      };
      const blob = await pdf(
        <CertificatePDF data={dataMakePDF} />
      ).toBlob();

      const formData = new FormData();
      formData.append('certificate', blob, 'certificate.pdf');

      axios({
        method: "post",
        url: `${baseUrl}${materialEndpoints.create}/${id}/certificate`,
        data: formData,
        headers: AuthorizeHeader(),
      })
        .then(function () {
          toast.success(LANGUAGE.CERTIFICATE_MAKE_SUCCESS);
          setIsCertificateMaking(false);
          getMaterial();
        })
        .catch(function (error) {
          if (error && error.data) {
            toast.error(error.data.message);
          } else {
            toast.error(error.response.data.message);
          }
          setIsCertificateMaking(false);
        });
    };

    if (material) {
      if (material.material_image_link) {
        const getFileExtension = (url) => url.split('/').pop().split('.').pop();
        const fileExtension = getFileExtension(material.material_image_link);
        setIsCertificateMaking(true);

        const response = await materialApi.getImageBuffer(id);
        if (response) {
          await createPDF(response, fileExtension);
        } else {
          await createPDF(null, null);
        }
      } else {
        setIsCertificateMaking(true);
        await createPDF(null, null);
      }
    };
  };

  const onClickDownloadCertificate = async () => {
    if (material) {
      try {
        setIsDownloadingCertificate(true);

        const response = await materialApi.getCertificate(id);

        // Create a blob from the response data
        const blob = new Blob([response.response]);

        const href = URL.createObjectURL(blob);

        const anchorElement = document.createElement('a');
        anchorElement.href = href;
        anchorElement.download = material.material_id + ".pdf";

        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
        setIsDownloadingCertificate(false);
      } catch (error) {
        setIsDownloadingCertificate(false);
        console.error('Error downloading file:', error);
      }
    };
  };

  const onClickCheckCertificate = async () => {
    navigate(routesGen.materialCertificate(id));
  };

  const onClickShowSat = async () => {
    navigate(routesGen.materialSat(id));
  };

  const onClickConfirmDelete = async () => {
    setIsDeleting(true);
    const { response, err } = await materialApi.delete(id);

    if (err) {
      setIsDeleting(false);
      toast.error(err.message);
    };
    if (response) {
      toast.success(LANGUAGE.DELETED_SUCCESS);
      setTimeout(() => {
        setIsDeleting(false);
        navigate(routes.MATERIALS);
      }, 1500);
    };
  };

  return (
    <>
      {isMobile ? (
        <MaterialDetailMobilePage
          material={material}
          user={user}
          onClickReturn={onClickReturn}
          onClickEdit={onClickEdit}
          onClickCertificateIssue={onClickCertificateIssue}
          onClickCheckCertificate={onClickCheckCertificate}
          onClickDownloadCertificate={onClickDownloadCertificate}
          onClickDownloadData={onClickDownloadData}
          onClickShowSat={onClickShowSat}
          isCertificateMaking={isCertificateMaking}
          isDownloadingCertificate={isDownloadingCertificate}
          isDownloadingData={isDownloadingData}
          onClickConfirmDelete={onClickConfirmDelete}
          isDeleting={isDeleting}
        />
      ) : (
        <MaterialDetailWebPage
          material={material}
          user={user}
          onClickReturn={onClickReturn}
          onClickEdit={onClickEdit}
          onClickCertificateIssue={onClickCertificateIssue}
          onClickCheckCertificate={onClickCheckCertificate}
          onClickDownloadCertificate={onClickDownloadCertificate}
          onClickDownloadData={onClickDownloadData}
          onClickShowSat={onClickShowSat}
          isCertificateMaking={isCertificateMaking}
          isDownloadingCertificate={isDownloadingCertificate}
          isDownloadingData={isDownloadingData}
          onClickConfirmDelete={onClickConfirmDelete}
          isDeleting={isDeleting}
        />
      )}
    </>
  );
};

export default MaterialDetailPage;