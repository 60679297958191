import {
  Box, Button, CircularProgress, Paper, Stack, Typography, circularProgressClasses,
  useMediaQuery
} from "@mui/material";
import Link from '@mui/material/Link';
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import authApi from '../api/auth.api';
import Animate from "../components/common/Animate";
import StackCol from '../components/common/StackCol';
import StackRow from "../components/common/StackRow";
import { TextFieldCustom } from '../components/common/TextFieldCustom';
import { TextFieldPassword } from '../components/password/PasswordComponents';
import { routes, routesGen } from "../configs/routes.config";
import { setUser } from '../redux/features/user.slice';
import LANGUAGE from "../utils/language.util";
import { IMAGES, VIDEOS } from "../assets";
import { useTheme } from "@emotion/react";


const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const videoRef = useRef(null);

  const [isLoginRequest, setIsLoginRequest] = useState(false);
  const [loginProgress, setLoginProgress] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const signinForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.EMAIL))
        .email(LANGUAGE.FIELD_INVALID(LANGUAGE.EMAIL)),
      password: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.PASSWORD))
    }),
    onSubmit: async values => {
      setIsLoginRequest(true);
      const params = {
        user_email: values.email,
        user_password: values.password
      };

      const interval = setInterval(() => {
        setLoginProgress(prev => prev + 100 / 40);
      }, 50);

      setTimeout(() => {
        clearInterval(interval);
      }, 2000);

      const { response, err } = await authApi.signin(params);
      if (response) {
        const { user } = response;
        dispatch(setUser(response));
        toast.success(LANGUAGE.LOGIN_SUCCESS);

        if (user) {
          const { material } = response.user;
          switch (user.user_role) {
            case 1:
              setTimeout(() => {
                navigate(routes.PARTNERS);
              }, 1500);
              break;
            case 2:
              setTimeout(() => {
                navigate(routes.USERS);
              }, 1500);
              break;
            case 3:
              if (!material) {
                setTimeout(() => {
                  navigate(routes.MATERIALS);
                }, 2100);
                return;
              }
              if (material && !material.material_url) {
                setTimeout(() => {
                  navigate(routesGen.materialUpdate(material.material_id));
                }, 2100);
              }
              if (material && material.material_url) {
                setTimeout(() => {
                  navigate(routesGen.certificateDetail(material.material_id));
                }, 2100);
              }
              break;
            default:
              setTimeout(() => {
                navigate(routes.HOME);
              }, 2100);
              break;
          }
        }

      }

      if (err) {
        setTimeout(() => {
          setIsLoginRequest(false);
          toast.error(err.message);
        }, 2000);
      }
    }
  });

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Prevent the default behavior
      event.preventDefault();
      signinForm.handleSubmit();
    }
  };

  useEffect(() => {
    // Function to handle the 'loadedmetadata' event
    const handleLoadedMetadata = () => {
      setVideoLoaded(true);
    };

    // Access the video element through the ref and attach event listener
    const video = videoRef.current;
    if (video) {
      video.addEventListener('loadedmetadata', handleLoadedMetadata);
    }

    // Cleanup function to remove event listener when component unmounts
    return () => {
      if (video) {
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
    };
  }, []);

  const useBreakpoint = () => {
    const theme = useTheme();

    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const isMd = useMediaQuery(theme.breakpoints.only('md'));
    const isLg = useMediaQuery(theme.breakpoints.only('lg'));
    const isXl = useMediaQuery(theme.breakpoints.only('xl'));

    let currentBreakpoint = 'unknown';

    if (isXs) currentBreakpoint = 'xs';
    if (isSm) currentBreakpoint = 'sm';
    if (isMd) currentBreakpoint = 'md';
    if (isLg) currentBreakpoint = 'lg';
    if (isXl) currentBreakpoint = 'xl';

    // const isMaxHeight944 = useMediaQuery('(max-height:944px)');
    // const isMaxHeight768 = useMediaQuery('(max-height:768px)');
    // const isMaxHeight640 = useMediaQuery('(max-height:640px)');

    // let currentBreakpoint = 'unknown';

    // if (isMaxHeight944) currentBreakpoint = 'isMaxHeight944';
    // if (isMaxHeight768) currentBreakpoint = 'isMaxHeight768';
    // if (isMaxHeight640) currentBreakpoint = 'isMaxHeight640';

    useEffect(() => {
      console.log(`Current breakpoint: ${currentBreakpoint}`);
    }, [currentBreakpoint]);

    return currentBreakpoint;
  };
  useBreakpoint();

  return (
    <Stack
      sx={{
        height: "100vh",
        // overflow: "hidden",
        // "::-webkit-scrollbar": { display: "none" }
      }}
      onKeyDown={handleKeyPress}
      position="relative"
    >
      <StackRow
        justifyContent={"center"}
        alignItems="center"
        sx={{
          padding: 2,
          height: { xl: "100px", lg: "100px", md: "80px", sm: "80px", xs: "80px" }
        }}>
        <Animate type="fade" delay={0.5}>
          <Box sx={{
            height: { xl: "60px", lg: "60px", md: "60px", sm: "60px", xs: "60px" },
            position: "relative"
          }}>
            <img src={IMAGES.logo} alt='logo' height="100%" />
          </Box>
        </Animate>
      </StackRow>
      <Box sx={{
        height: { xl: "calc(100% - 100px)", lg: "calc(100% - 100px)", md: "calc(100% - 80px)", sm: "calc(100% - 80px)", xs: "calc(100% - 80px)" },
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        minHeight: "500px"
      }}>
        <video
          ref={videoRef}
          preload="none"
          autoPlay
          muted
          loop
          playsInline
          width="100%"
          height="100%"
          style={{
            objectFit: "cover",
            zIndex: -1,
            position: "absolute",
          }}
        >
          <source src={VIDEOS.user_screen_bg} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {isLoginRequest ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1000
            }}
          >
            <Box position="relative">
              <CircularProgress
                variant="determinate"
                sx={{ color: "white" }}
                size={100}
                value={100}
              />
              <CircularProgress
                variant="determinate"
                value={loginProgress}
                size={100}
                sx={{
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: "round"
                  },
                  position: "absolute",
                  left: 0,
                  color: "green"
                }}
              />
            </Box>
          </Stack>
        ) :
          (videoLoaded &&
            // <Animate sx={{
            //   // position: "relative"
            // }}>
            <Box
              component={Paper}
              sx={{
                bgcolor: "white",
                width: { xl: "50%", lg: "50%", md: "50%", sm: "50%", xs: "50%" },
                height: { xl: "90%", lg: "90%", md: "90%", sm: "90%", xs: "90%" },
                borderRadius: 5,
                minWidth: "350px",
                minHeight: "350px",
                maxHeight: "700px",
                maxWidth: "700px"
              }}>
              <StackCol
                alignItems="center"
                sx={{
                  pt: { lg: "5vh", md: "5vh", sm: 2, xs: 2 }
                }}
                spacing={1}
              >
                <Typography sx={{
                  fontSize: { xl: 40, lg: 40, md: 30, sm: 20, xs: 25 },
                  whiteSpace: 'nowrap',
                  fontFamily: "Noto Serif JP",
                  color: "gray"
                }}>
                  {LANGUAGE.LOGIN}
                </Typography>
                <Typography sx={{
                  fontFamily: "Noto Serif JP",
                  color: "#3f7ec1"
                }}>
                  LOG IN
                </Typography>
              </StackCol>
              <Box
                component="form"
                onSubmit={signinForm.handleSubmit}
                sx={{ mt: { xl: 5, lg: "5vh", md: 2, sm: 1, xs: 2 } }}
              >
                <StackCol spacing={2} alignItems='center'>
                  <StackCol spacing={1} sx={{ width: "80%" }}
                  >
                    <Typography sx={{
                      fontFamily: "Noto Serif JP",
                      color: "#3f7ec1"
                    }}>
                      E-mail Address
                    </Typography>
                    <TextFieldCustom
                      id={"email"}
                      name={"email"}
                      form={signinForm}
                      placeholder={LANGUAGE.EMAIL}
                    />
                  </StackCol>
                  <StackCol spacing={1} sx={{ width: "80%" }}
                  >
                    <Typography sx={{
                      fontFamily: "Noto Serif JP",
                      color: "#3f7ec1"
                    }}>
                      Password
                    </Typography>
                    <TextFieldPassword
                      form={signinForm}
                      id={"password"}
                      name={"password"}
                      placeholder={LANGUAGE.PASSWORD}
                      showPassword={showPassword}
                      toggleVisibility={togglePasswordVisibility}
                      autoComplete={true}
                    />
                  </StackCol>
                </StackCol>
                <StackCol alignItems='center' sx={{ mt: { xl: "5vh", lg: "3vh", md: "5vh", sm: 2, xs: 5 } }}>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    sx={{
                      width: "200px",
                      borderRadius: 3,
                      fontSize: 20,
                    }}>
                    {LANGUAGE.LOGIN}
                  </Button>
                  <StackRow
                    justifyContent="center"
                    alignItems="center"
                    sx={{ pb: 2 }}
                  >
                    <Typography color="blue" fontWeight="bold">
                      <Link
                        underline="none"
                        component={"button"}
                        onClick={() => navigate(routes.PASSWORD_FORGOT)}
                      >
                        {LANGUAGE.PASSWORD_FORGOT}
                      </Link>
                    </Typography>
                  </StackRow>
                </StackCol>
              </Box>
            </Box>
            // </Animate>
          )
        }
      </Box >
    </Stack >
  );
};

export default LoginPage;