import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { APP_STATE } from '../configs/constants.config';
import { routes, routesGen } from '../configs/routes.config';
import { setAppState } from '../redux/features/app.state.slice';
import { authUtils } from '../utils/auth.utils';

const DashboardPage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppState(APP_STATE.DASHBOARD));
  }, [dispatch]);

  useEffect(() => {
    const checkAuth = async () => {
      const user = await authUtils.isAuthenticated();
      if (!user) {
        if (!window.location.pathname.includes('/password/forgot')
          && !window.location.pathname.includes('/password/reset')) {
          navigate('/signin');
        }
      }
      if (user) {
        const { material } = user.user;
        if (user.user.user_role === 1) {
          navigate(routes.PARTNERS);
        }
        if (user.user.user_role === 2) {
          navigate(routes.USERS);
        }
        if (user.user.user_role === 3) {
          if (!material) {
            return navigate(routes.MATERIALS);
          }
          if (material && !material.material_url) {
            return navigate(routesGen.materialUpdate(material.material_id));
          }
          if (material && material.material_url) {
            return navigate(routesGen.certificateDetail(material.material_id));
          }
        }
      }
    };
    checkAuth();
  }, [dispatch, navigate]);

  return (
    <></>
  );
};

export default DashboardPage;