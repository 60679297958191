import { LoadingButton } from "@mui/lab";
import {
  Box, Typography
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import authApi from '../../api/auth.api';
import StackCol from '../../components/common/StackCol';
import { TextFieldPassword } from '../../components/password/PasswordComponents';
import { routes } from "../../configs/routes.config";
import LANGUAGE from "../../utils/language.util";

const PasswordResetPage = () => {
  const navigate = useNavigate();

  const { token } = useParams();

  const [isForgotRequest, setIsForgotRequest] = useState(false);
  const [isResetSuccess, setIsResetSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const togglePasswordConfirmVisibility = () => {
    setShowPasswordConfirm((prevShowPassword) => !prevShowPassword);
  };

  const formResetPass = useFormik({
    initialValues: {
      new_password: "",
      new_password_repeat: ""
    },
    validationSchema: Yup.object({
      new_password: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.PASSWORD_NEW)),
      new_password_repeat: Yup.string()
        .oneOf([Yup.ref('new_password'), null], LANGUAGE.PASSWORD_MUST_MATCH)
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.PASSWORD_NEW_CONFIRM)),
    }),
    onSubmit: async values => {
      setIsForgotRequest(true);
      setIsResetSuccess(false);
      const params = {
        reset_password_token: token,
        new_password: values.new_password,
        new_password_repeat: values.new_password_repeat,
      };

      const { response, err } = await authApi.passwordReset(params);
      if (response) {
        toast.success(LANGUAGE.PASSWORD_RESET_SUCCESS);
        setIsResetSuccess(true);
        setIsForgotRequest(false);

        setTimeout(() => {
          navigate(routes.SIGNIN);
        }, 3000);
      }

      if (err) {
        setTimeout(() => {
          setIsForgotRequest(false);
          toast.error(err.message);
        }, 2000);
      }
    }
  });

  return (
    <>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        pt: 20
      }}>
        <Box
          component="form"
          maxWidth={400}
          width="100%"
          onSubmit={formResetPass.handleSubmit}
        >
          <StackCol spacing={3} alignItems='center'>
            <TextFieldPassword
              form={formResetPass}
              id={"new_password"}
              name={"new_password"}
              label={LANGUAGE.PASSWORD_NEW}
              showPassword={showPassword}
              toggleVisibility={togglePasswordVisibility}
            />
            <TextFieldPassword
              form={formResetPass}
              id={"new_password_repeat"}
              name={"new_password_repeat"}
              label={LANGUAGE.PASSWORD_NEW_CONFIRM}
              showPassword={showPasswordConfirm}
              toggleVisibility={togglePasswordConfirmVisibility}
            />
            <LoadingButton
              type="submit"
              size="large"
              variant="contained"
              loading={isForgotRequest}
              sx={{
                width: "200px"
              }}>
              {LANGUAGE.SEND}
            </LoadingButton>
          </StackCol>
        </Box>
      </Box>
      {isResetSuccess &&
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 4,
          }}>
          <Typography sx={{
            maxWidth: "380px",
            color: "green",
            align: "justify"
          }}>
            {LANGUAGE.PASSWORD_RESET_NOTIFICATION}
          </Typography>
        </Box>
      }
    </>
  );
};

export default PasswordResetPage;