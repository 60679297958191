import { callAPI } from "./axios.client";


export const partnerEndpoints = {
  list: "partners",
  create: "partners",
  getOne: ({ id }) => `partners/${id}`,
  update: ({ id }) => `partners/${id}`,
  delete: ({ id }) => `partners/${id}`,
};


const partnerApi = {
  list: async (query) => await callAPI("get", `${partnerEndpoints.list}?page=${query.page ? query.page : 0}`),
  create: async (params) => await callAPI("post", partnerEndpoints.create, params),
  getOne: async (id) => await callAPI("get", partnerEndpoints.getOne({ id })),
  update: async (id, params) => await callAPI("put", partnerEndpoints.update({ id }), params),
  delete: async (id) => await callAPI("delete", partnerEndpoints.delete({ id })),
};

export default partnerApi;
