import { LoadingButton } from '@mui/lab';
import { Box, Paper, Table, TableBody, TableContainer } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import partnerApi from '../../api/partner.api';
import { HeaderBox } from '../../components/common/HeaderBox';
import StackCol from '../../components/common/StackCol';
import StackRow from '../../components/common/StackRow';
import { TableRowInForm, TableRowInFormTwoTextField } from '../../components/common/TableRowCustom';
import LANGUAGE from '../../utils/language.util';


const PartnerCreatePage = () => {
  const navigate = useNavigate();

  const [isRegisterRequest, setIsRegisterRequest] = useState(false);

  const form = useFormik({
    initialValues: {
      company_name: "",
      first_name: "",
      last_name: "",
      postcode: "",
      prefecture: "",
      address: "",
      building: "",
      tel: "",
      email: "",
      contact_person_first_name: "",
      contact_person_last_name: "",
      contact_person_tel: "",
      contact_person_email: "",
      bank_number: "",
    },
    validationSchema: Yup.object({
      company_name: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.COMPANY_NAME)),
      first_name: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.FIRST_NAME)),
      last_name: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.LAST_NAME)),
      email: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.REPRESENTATIVE_EMAIL))
        .email(LANGUAGE.FIELD_INVALID(LANGUAGE.REPRESENTATIVE_EMAIL)),
      contact_person_first_name: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.CONTACT_PERSON_NAME)),
      contact_person_last_name: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.CONTACT_PERSON_NAME)),
      contact_person_tel: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.CONTACT_PERSON_TEL)),
      contact_person_email: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.CONTACT_PERSON_EMAIL))
        .email(LANGUAGE.FIELD_INVALID(LANGUAGE.CONTACT_PERSON_EMAIL)),
      tel: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.TELEPHONE)),
      prefecture: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.PREFECTURE)),
      address: Yup.string()
        .required(LANGUAGE.FIELD_REQUIRED(LANGUAGE.ADDRESS_PREFECTURE_BELOW)),
    }),
    onSubmit: async values => {
      setIsRegisterRequest(true);

      const params = {
        company_name: values.company_name,
        first_name: values.first_name,
        last_name: values.last_name,
        postcode: values.postcode,
        prefecture: values.prefecture,
        address: values.address,
        building: values.building,
        tel: values.tel,
        email: values.email,
        contact_person_first_name: values.contact_person_first_name,
        contact_person_last_name: values.contact_person_last_name,
        contact_person_tel: values.contact_person_tel,
        contact_person_email: values.contact_person_email,
        bank_number: values.bank_number,
      };

      const { response, err } = await partnerApi.create(params);
      if (response) {
        form.resetForm();
        toast.success(LANGUAGE.REGISTERED);

        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }

      if (err) {
        toast.error(err.message);
        setIsRegisterRequest(false);
      }
    }
  });

  return (
    <StackCol>
      <HeaderBox title={LANGUAGE.PARTNER_MANAGEMENT} />
      <Box component="form" onSubmit={form.handleSubmit}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRowInForm
                title={LANGUAGE.COMPANY_NAME}
                id="company_name"
                form={form}
              ></TableRowInForm>
              <TableRowInFormTwoTextField
                title={LANGUAGE.REPRESENTATIVE_NAME}
                idFirst="first_name"
                idSecond="last_name"
                form={form}
              ></TableRowInFormTwoTextField>
              <TableRowInForm
                title={LANGUAGE.COMPANY_POST_CODE}
                id="postcode"
                form={form}
                sx={{ width: { xl: "25%", lg: "30%", md: "40%", xs: "43%" } }}
              ></TableRowInForm>
              <TableRowInForm
                title={LANGUAGE.PREFECTURE}
                id="prefecture"
                form={form}
              ></TableRowInForm>
              <TableRowInForm
                title={LANGUAGE.ADDRESS_PREFECTURE_BELOW}
                id="address"
                form={form}
              ></TableRowInForm>
              <TableRowInForm
                title={LANGUAGE.BUILDING_NAME}
                id="building"
                form={form}
              ></TableRowInForm>
              <TableRowInForm
                title={LANGUAGE.REPRESENTATIVE_TELEPHONE}
                id="tel"
                form={form}
              ></TableRowInForm>
              <TableRowInForm
                title={LANGUAGE.REPRESENTATIVE_EMAIL}
                id="email"
                form={form}
              ></TableRowInForm>
              <TableRowInFormTwoTextField
                title={LANGUAGE.CONTACT_PERSON_NAME}
                idFirst="contact_person_first_name"
                idSecond="contact_person_last_name"
                form={form}
              ></TableRowInFormTwoTextField>
              <TableRowInForm
                title={LANGUAGE.CONTACT_PERSON_TEL}
                id="contact_person_tel"
                form={form}
              ></TableRowInForm>
              <TableRowInForm
                title={LANGUAGE.CONTACT_PERSON_EMAIL}
                id="contact_person_email"
                form={form}
              ></TableRowInForm>
              <TableRowInForm
                title={LANGUAGE.BANK_NUMBER}
                id="bank_number"
                form={form}
              ></TableRowInForm>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <StackRow justifyContent={"center"}>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={isRegisterRequest}
          onClick={form.handleSubmit}
        >
          {LANGUAGE.REGISTER}
        </LoadingButton>
      </StackRow>
    </StackCol >
  );
};

export default PartnerCreatePage;