import { Breadcrumbs, Button, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const MBreadcrumb = () => {
  const { breadcrumbs } = useSelector((state) => state.appState);

  return (
    breadcrumbs.length > 0 && (
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumbs.map((bread, index) => (
          bread.link ? (
            <Button
              key={index}
              component={Link}
              to={bread.link}
              sx={{ textTransform: 'none' }}
            >
              {bread.displayText}
            </Button>
          ) : (
            <Typography key={index}>{bread.displayText}</Typography>
          )
        ))}
      </Breadcrumbs>
    )
  );
};

export default MBreadcrumb;