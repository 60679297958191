import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import React from 'react';
import { BackgroundGrey, BtnUploadColor } from '../../styles/custom.style';

const BoxUploadImage = (props) => {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <Box
      sx={{
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: BackgroundGrey,
        background: props.file ? `url(${URL.createObjectURL(props.file)})` : null,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      {!props.file &&
        <LoadingButton
          component="label"
          variant="contained"
          size="large"
          sx={{
            height: "60px",
            width: "250px",
            bgcolor: BtnUploadColor,
            "&:hover": {
              bgcolor: BtnUploadColor,
            },
            borderRadius: "30px"
          }}
        >
          <VisuallyHiddenInput
            type="file"
            onChange={props.handleFileChange}
            accept={props.accept}
          />
          {props.title}
        </LoadingButton>
      }
    </Box>
  );
};

const BoxUploadVideo = (props) => {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <Box
      sx={{
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: BackgroundGrey,
        background: props.file ? `url(${URL.createObjectURL(props.file)})` : null,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      {props.file ?
        <video height="200" controls>
          <source src={URL.createObjectURL(props.file)} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        : (
          <LoadingButton
            component="label"
            variant="contained"
            size="large"
            sx={{
              height: "60px",
              width: "250px",
              bgcolor: BtnUploadColor,
              "&:hover": {
                bgcolor: BtnUploadColor,
              },
              borderRadius: "30px"
            }}
          >
            <VisuallyHiddenInput
              type="file"
              onChange={props.handleFileChange}
              accept={props.accept}
            />
            {props.title}
          </LoadingButton>
        )}
      {/* {!props.file &&
        <LoadingButton
          component="label"
          variant="contained"
          size="large"
          sx={{
            height: "60px",
            width: "250px",
            bgcolor: BtnUploadColor,
            "&:hover": {
              bgcolor: BtnUploadColor,
            },
            borderRadius: "30px"
          }}
        >
          <VisuallyHiddenInput
            type="file"
            onChange={props.handleFileChange}
            accept={props.accept}
          />
          {props.title}
        </LoadingButton>
      } */}
    </Box>
  );
};


export {
  BoxUploadImage,
  BoxUploadVideo
};

