import React, { useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { IMAGES, VIDEOS } from '../../assets';
import { Box, Typography } from '@mui/material';
import Animate from '../common/Animate';
import { routes } from '../../configs/routes.config';
import StackCol from '../common/StackCol';
import StackRow from '../common/StackRow';
import { setUser } from '../../redux/features/user.slice';
import { useDispatch, useSelector } from 'react-redux';

const MaterialUserLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const videoRef = useRef(null);

  const { materialTab } = useSelector((state) => state.appState);

  const requestLogout = () => {
    dispatch(setUser(null));
    navigate(routes.SIGNIN);
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        minWidth: "400px",
        minHeight: "900px",
        display: "flex",
        justifyContent: "center",
        pb: 4
      }}
    >
      <video
        hidden={materialTab === "satellite"}
        preload="none"
        ref={videoRef}
        autoPlay
        muted
        loop
        playsInline
        style={{
          width: "100%",
          height: "100%",
          minWidth: "400px",
          minHeight: "900px",
          objectFit: "cover",
          position: "fixed",
          zIndex: -9999
        }}
      >
        <source src={VIDEOS.user_screen_bg} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <video
        hidden={materialTab !== "satellite"}
        preload="none"
        ref={videoRef}
        autoPlay
        muted
        loop
        playsInline
        style={{
          width: "100%",
          height: "100%",
          minWidth: "400px",
          minHeight: "900px",
          objectFit: "cover",
          position: "fixed",
          zIndex: -9999
        }}
      >
        <source src={VIDEOS.where_is_now} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <StackCol sx={{
        width: { xl: "70%", lg: "80%", md: "90%", sm: "95%", xs: "95%" }
      }} spacing={0}>
        <StackRow justifyContent="end" sx={{
          pt: 1
        }}>
          <Typography
            color="white"
            sx={{
              cursor: "pointer",
              fontSize: { lg: 20, md: 15, sm: 15, xs: 13 }
            }}
            onClick={requestLogout}
          >
            ログアウト | LOG OUT
          </Typography>
        </StackRow>
        <StackRow
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "80px",
            mb: { lg: 1, md: 1, sm: 1, xs: 0 },
            mt: { lg: 1, md: 1, sm: 1, xs: 0 }
          }}>
          <Animate type="fade" delay={0.5}>
            <Box height={{ lg: 60, md: 60, sm: 60, xs: 60 }}>
              <img
                src={IMAGES.logo_user}
                alt='logo'
                height="100%"
                style={{
                  cursor: 'pointer',

                }}
                onClick={() => navigate(routes.HOME)}
              />
            </Box>

          </Animate>
        </StackRow>
        <Outlet />
      </StackCol>
    </Box>
  );
};

export default MaterialUserLayout;