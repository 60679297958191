import { Document, Image, Page, StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import { IMAGES } from '../../assets';
import CertificateDecor from '../../components/svg/CertificateDecor';
import CertificateFooter from '../../components/svg/CertificateFooter';
import CertificateFrame from '../../components/svg/CertificateFrame';
import CertificateText from '../../components/svg/CertificateText';


const CertificatePDF = (props) => {
  const { data } = props;
  const styles = StyleSheet.create({
    certificate_background: {
      position: 'absolute',
      zIndex: -1,
      width: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.5)'
    }
  });

  return (
    <Document style={{
    }}>
      <Page
        size="A4"
        style={{
          position: 'relative',
          alignItems: 'center',
        }}
      >
        <Image
          src={IMAGES.certificate_background}
          style={styles.certificate_background}
        />
        <CertificateDecor />
        <View style={{
          position: "relative",
          alignItems: "center"
        }}>
          <CertificateFrame
            data={data}
          />
          <CertificateText
            data={data}
          />
        </View>
        <CertificateFooter />
      </Page>
    </Document>
  );
};

export default CertificatePDF;