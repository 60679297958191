import { configureStore } from "@reduxjs/toolkit";
import appStateSlice from "./features/app.state.slice";
import userReducer from './features/user.slice';
import authModalSlice from "./features/auth.modal.slice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    appState: appStateSlice,
    authModal: authModalSlice,
  }
});