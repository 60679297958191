import { Button } from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import materialApi from '../../api/material.api';
import StackCol from '../../components/common/StackCol';
import { BtnDownLoadColor } from '../../styles/custom.style';
import { getDetailTimeFromDate } from '../../utils/date.utils';
import LANGUAGE from '../../utils/language.util';
import CertificatePDF from './CertificatePDF';

const CertificateCheck = () => {
  const { id } = useParams();

  const [material, setMaterial] = useState();
  const [imageBuffer, setImageBuffer] = useState(null);
  const [imageExt, setImageExt] = useState("");
  const [sizeImage, setSizeImage] = useState({
    hasSize: false,
    height: "100%",
    width: "100%"
  });
  const [dataMakePDF, setDataMakePDF] = useState(null);

  useEffect(() => {
    const getImage = async () => {
      const response = await materialApi.getImageBuffer(id);
      if (response) {
        setImageBuffer(response);
      }
    };

    if (material) {
      if (material.material_image_link) {
        const getFileExtension = (url) => url.split('/').pop().split('.').pop();
        const fileExtension = getFileExtension(material.material_image_link);
        setImageExt(fileExtension);
        getImage();
      }

      if (material.trace_json_url) {
        const detailTime = getDetailTimeFromDate(material.updated_date);
        const sentMessage = `送信日時  ${detailTime.year} 年 ${detailTime.month} 月 ${detailTime.day} 日 ${detailTime.hour} 時 ${detailTime.minute} 分`;
        setDataMakePDF({
          nickname: material.nickname,
          username: material.user.display_name,
          id: material.material_id,
          message: material.message,
          sentMessage: sentMessage,
          imageBuffer: imageBuffer,
          imageExt: imageExt,
          sizeImage: sizeImage
        });
      }
    }
  }, [material, id, imageExt, imageBuffer, sizeImage]);

  useEffect(() => {
    const getMaterial = async () => {
      const { response, err } = await materialApi.getOne(id);

      if (err) toast.error(err.message);
      if (response) {
        setMaterial(response);
      };
    };
    getMaterial();
  }, [id]);

  useEffect(() => {
    if (material && material.material_image_link) {
      const img = new Image();
      img.src = material.material_image_link;
      img.onload = () => {
        if (img.width > img.height) {
          setTimeout(() => {
            setSizeImage({
              hasSize: true,
              width: "60%",
              height: "auto"
            });
          }, 500);
        } else {
          setTimeout(() => {
            setSizeImage({
              hasSize: true,
              width: "auto",
              height: "65%"
            });
          }, 500);
        }
      };
    }
  }, [material]);

  return (
    (id && material && imageBuffer && dataMakePDF &&
      <StackCol>
        <PDFDownloadLink document={
          <CertificatePDF data={dataMakePDF} />
        } fileName={id}>
          {({ loading }) => (loading ? <Button>Loading...</Button> : <Button
            variant="contained"
            sx={{
              width: "200px",
              bgcolor: BtnDownLoadColor,
              "&:hover": {
                bgcolor: BtnDownLoadColor,
              },
            }}
          >
            {LANGUAGE.DOWNLOAD}
          </Button>)}
        </PDFDownloadLink>
        <PDFViewer showToolbar={false} width="1200px" height="1200px">
          <CertificatePDF data={dataMakePDF} />
        </PDFViewer>
      </StackCol>
    ));
};

export default CertificateCheck;