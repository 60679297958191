import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addBreadcrumb, removeBreadcrumbAtLevel, setAppState } from '../../redux/features/app.state.slice';
import { roleUtils } from '../../utils/auth.utils';
import NotPermission from './../../pages/NotPermission';

const Page = (props) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (props.state) {
      dispatch(setAppState(props.state));
    }
    if (props.breadcrumb) {
      dispatch(addBreadcrumb(props.breadcrumb));
    }
  }, [dispatch, props]);

  useEffect(() => {
    return () => {
      if (props.breadcrumb) {
        dispatch(removeBreadcrumbAtLevel({ level: props.breadcrumb?.level }));
      }
    };
  }, [dispatch, props]);

  return (
    (props.roles && user) ?
      ((roleUtils.isAvailable(props.roles, user.user_role)) ? (props.element) : <NotPermission />) : (props.element)
  );
};

export default Page;
