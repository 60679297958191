import { Button, IconButton, InputAdornment, TableCell, TableRow, TextField, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { VisibilityIcon, VisibilityOffIcon } from './Icon';

const TableRowTwoText = (props) => {
  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell align='right' sx={{ width: "30%" }}>
        <Typography>
          {props.title}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{
        ...props.sx2
      }}>
        <Typography>
          {props.value}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const TableRowTextAndButton = (props) => {
  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell align='right' sx={{ width: "30%" }}>
        <Typography>
          {props.title}
        </Typography>
      </TableCell>
      {props.value &&
        <TableCell align="left">
          {props.to ?
            <Button
              color="primary"
              component={Link}
              to={props.to}
              sx={{ textTransform: 'none' }}
            >
              {props.value}
            </Button>
            :
            <Typography>
              {props.value}
            </Typography>
          }
        </TableCell>
      }
    </TableRow>
  );
};

const TableRowInForm = (props) => {
  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell align='right' sx={{ width: "30%" }}>
        <Typography>
          {props.title}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <TextFieldInForm
          sx={{ ...(props.sx ? props.sx : { width: '100%' }) }}
          id={props.id}
          form={props.form}
        >
        </TextFieldInForm>
      </TableCell>
    </TableRow>
  );
};

const TableRowInFormTwoTextField = (props) => {
  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell align='right' sx={{ width: "30%" }}>
        <Typography>
          {props.title}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <TextFieldInForm
          sx={{ width: "50%", paddingRight: 2 }}
          id={props.idFirst}
          label={props.labelFirst}
          form={props.form}
        >
        </TextFieldInForm>
        <TextFieldInForm
          sx={{ width: "50%" }}
          id={props.idSecond}
          label={props.labelSecond}
          form={props.form}
        >
        </TextFieldInForm>
      </TableCell>
    </TableRow>
  );
};

const TextFieldInForm = (props) => {
  return (
    <TextField
      label={props.label}
      id={props.id}
      name={props.id}
      type="text"
      value={props.form.values[props.id]}
      onChange={props.form.handleChange}
      error={props.form.touched[props.id] && props.form.errors[props.id] !== undefined}
      helperText={props.form.touched[props.id] && props.form.errors[props.id]}
      sx={props.sx}
      size="small"
      inputProps={{
        autoComplete: 'off',
        form: {
          autoComplete: 'off',
        },
      }}
    />
  );
};

const TableRowWithPasswordField = (props) => {
  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell align='right'>
        <Typography>
          {props.title}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <TextField
          id={props.id}
          name={props.id}
          type={props.showPassword ? 'text' : 'password'}
          value={props.form.values[props.id]}
          onChange={props.form.handleChange}
          error={props.form.touched[props.id] && props.form.errors[props.id] !== undefined}
          helperText={props.form.touched[props.id] && props.form.errors[props.id]}
          sx={{ width: "100%" }}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={props.togglePasswordVisibility}>
                  {props.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export {
  TableRowInForm,
  TableRowInFormTwoTextField,
  TableRowTextAndButton,
  TableRowTwoText,
  TableRowWithPasswordField
};

