
import { ReactComponent as CompanySVG } from "../../assets/images/company.svg";
import { ReactComponent as GroupSVG } from "../../assets/images/group.svg";

import AddBoxIcon from '@mui/icons-material/AddBox';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import LockResetIcon from '@mui/icons-material/LockReset';
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';

import CertificateIconPNG from "../../assets/images/certificate_icon.png";
import ManagementIconPNG from "../../assets/images/management_icon.png";
import LocationIconPNG from "../../assets/images/location-icon.png";


export const CompanyIcon = (props) => (
  <CompanySVG />
);

export const GroupIcon = (props) => (
  <GroupSVG />
);

export const CertificateIcon = (props) => (
  <img
    src={CertificateIconPNG}
    alt="CertificateIcon"
    width={22}
  />
);

export const ManagementIcon = (props) => (
  <img
    src={ManagementIconPNG}
    alt="ManagementIcon"
    width={22}
  />
);

export {
  AddBoxIcon,
  ClearIcon,
  CloseIcon,
  LockResetIcon,
  LogoutOutlinedIcon,
  ManageAccountsIcon,
  UploadFileIcon,
  VisibilityIcon,
  VisibilityOffIcon,
  DeleteIcon,
  LocationIconPNG,
  WarningIcon
};
