import React from 'react';
import { Stack } from '@mui/material';

const StackRow = (props) => {
  return (
    <Stack
      spacing={props.spacing ? props.spacing : 2}
      direction="row"
      {...props}
    />
  );
};

export default StackRow;
