import { Button, Paper, Table, TableBody, TableContainer } from '@mui/material';
import React, { useState } from 'react';
import { HeaderBox } from '../../components/common/HeaderBox';
import StackCol from '../../components/common/StackCol';
import StackRow from '../../components/common/StackRow';
import { TableRowTextAndButton, TableRowTwoText } from '../../components/common/TableRowCustom';
import { ButtonMaterialCustom, ButtonMaterialLoadingCustom, MaterialPhoto } from '../../components/material/MaterialComponents';
import { routesGen } from '../../configs/routes.config';
import { BtnCheckCertificateColor, BtnDeleteColor, BtnDownLoadColor, BtnNFTRequestColor, BtnSATShowColor } from '../../styles/custom.style';
import { formattedDate, formattedDateNotTime } from '../../utils/date.utils';
import LANGUAGE from '../../utils/language.util';
import AlertDeleteDialog from '../../components/dialog/AlertDeleteDialog';


const MaterialDetailMobilePage = (props) => {
  const { material, user, onClickEdit, onClickCertificateIssue, onClickCheckCertificate, onClickDownloadCertificate, onClickDownloadData, onClickShowSat, isCertificateMaking, isDownloadingCertificate, isDownloadingData, onClickConfirmDelete, isDeleting } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const onClickDelete = () => {
    setOpenDialog(true);
  };

  const onDialogClickDelete = () => {
    setOpenDialog(false);
    onClickConfirmDelete();
  };

  return (
    <>
      <StackCol>
        <HeaderBox title={LANGUAGE.REGISTRATION_INFORMATION} />
        {material &&
          <>
            <StackCol>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRowTwoText
                      title={LANGUAGE.TARGET_PERSON_NAME}
                      value={material.nickname}
                    />
                    <TableRowTwoText
                      title={LANGUAGE.MONTHLY_MEMORIAL_DATE}
                      value={formattedDateNotTime(material.monthly_memorial_date)}
                    />
                    <TableRowTwoText
                      title={LANGUAGE.MESSAGE}
                      value={material.message}
                    />
                    <TableRowTwoText
                      title={LANGUAGE.DESIRED_SEND_DATE}
                      value={formattedDateNotTime(material.desired_send_date)}
                    />
                    <TableRowTwoText
                      title={LANGUAGE.SEND_DATE}
                      value={formattedDate(material.json_send_date)}
                    />
                    <TableRowTwoText
                      title={LANGUAGE.SAT_NAME}
                      value="STARLINK"
                    />
                    <TableRowTwoText
                      title={LANGUAGE.CERTIFICATE_ISSUE_DATE}
                      value={formattedDate(material.certificate_issue_date)}
                    />
                    <TableRowTextAndButton
                      title={LANGUAGE.APPLICANT_NAME}
                      value={material.user.display_name}
                      to={(user && user.user_role === 3) ? null : routesGen.userDetail(material.user.user_id)}
                    >
                    </TableRowTextAndButton>
                    <TableRowTwoText
                      title={LANGUAGE.EMAIL_NOTIFICATION_1}
                      value={material.email_notification_1}
                    />
                    <TableRowTwoText
                      title={LANGUAGE.EMAIL_NOTIFICATION_2}
                      value={material.email_notification_2}
                    />
                    <TableRowTwoText
                      title={LANGUAGE.EMAIL_NOTIFICATION_3}
                      value={material.email_notification_3}
                    />
                    <TableRowTwoText
                      title={LANGUAGE.EMAIL_NOTIFICATION_4}
                      value={material.email_notification_4}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
              <MaterialPhoto
                material_image_link={material.material_image_link}
              />
            </StackCol>
            <StackCol spacing={2}>
              <StackRow sx={{ width: "100%", display: "flex", margin: "auto" }} spacing={2}>
                {!material.trace_json_url &&
                  <Button
                    variant="contained"
                    sx={{ width: "30%", marginLeft: "auto !important", marginRight: "auto !important" }}
                    onClick={onClickEdit}
                  >
                    {LANGUAGE.EDIT}
                  </Button>
                }
                {(material.trace_json_url && user && user.user_role !== 3 && !material.certificate_url && material.material_url) && (
                  <ButtonMaterialLoadingCustom
                    loading={isCertificateMaking}
                    color={BtnNFTRequestColor}
                    btnName={LANGUAGE.CERTIFICATE_ISSUE}
                    onClick={onClickCertificateIssue}
                  />
                )}
                {(material.material_url && !material.certificate_url && user && user.user_role !== 3) && (
                  <ButtonMaterialLoadingCustom
                    loading={isDownloadingData}
                    color={BtnDeleteColor}
                    btnName={LANGUAGE.DOWNLOAD_DATA}
                    onClick={onClickDownloadData}
                  />
                )}
                {
                  material.certificate_url &&
                  <ButtonMaterialLoadingCustom
                    loading={isDownloadingCertificate}
                    color={BtnDownLoadColor}
                    btnName={LANGUAGE.DOWNLOAD_CERTIFICATE}
                    onClick={onClickDownloadCertificate}
                  />
                }
                {
                  (material.material_url && user && user.user_role === 1) &&
                  <ButtonMaterialCustom
                    color={BtnCheckCertificateColor}
                    btnName={LANGUAGE.CERTIFICATE_CHECK}
                    onClick={onClickCheckCertificate}
                  />
                }
                {
                  (material.sat_name) &&
                  <ButtonMaterialCustom
                    color={BtnSATShowColor}
                    btnName={LANGUAGE.SAT_SHOW}
                    onClick={onClickShowSat}
                  />
                }
                {
                  (user.user_role !== 3) &&
                  <ButtonMaterialLoadingCustom
                    color={BtnDeleteColor}
                    btnName={LANGUAGE.DELETE}
                    onClick={onClickDelete}
                    loading={isDeleting}
                  />
                }
              </StackRow>
            </StackCol>
          </>
        }
      </StackCol>
      <AlertDeleteDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onDialogClickDelete={onDialogClickDelete}
      /></>

  );
};

export default MaterialDetailMobilePage;