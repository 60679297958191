import React from 'react';
import { Svg, G, Path, Defs, LinearGradient, Stop, Rect, Line } from '@react-pdf/renderer';
import CertificateImage from './CertificateImage';
import CertificateMessage from './CertificateMessage';

const CertificateFrame = (props) => {
  console.log(props);
  const { imageBuffer, imageExt, sentMessage } = props.data;
  return (
    <Svg height="587.381px" width="534.8343px" viewBox="0 0 534.8343 587.381"
      style={{
        marginTop: "20px",
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        zIndex: -1
      }}>
      <Defs>
        <LinearGradient id="1" x1="12.26" y1="240.53" x2="436.17" y2="240.53"
          gradientTransform="translate(-16.31 464.74) rotate(-90)" gradientUnits="userSpaceOnUse">
          <Stop offset="0.82" stopColor="#006cba" />
          <Stop offset="0.99" stopColor="#00599b" />
        </LinearGradient>
        <LinearGradient id="2" x1="224.21" y1="473.05" x2="224.21" y2="8" gradientUnits="userSpaceOnUse">
          <Stop offset="0" stopColor="#1f9ad7" />
          <Stop offset="1" stopColor="#00599b" />
        </LinearGradient>
      </Defs>
      <G>
        <G>
          <Rect width="534.8343" height="587.381" fill="#fff" opacity="0.7" />
          <Rect x="-11.4715" y="39.5158" width="557.7768" height="507.2287"
            transform="translate(560.547 25.7133) rotate(90)"
            fill="none" stroke-miterlimit="10" opacity="0.7" stroke="#1f9ad7" />
          <Path
            d="M526.1308,560.0073V25.4726A16.8852,16.8852,0,0,1,509.132,8.7033H25.7017A16.8852,16.8852,0,0,1,8.7029,25.4726V560.0341a16.8649,16.8649,0,0,1,16.46,16.7425H509.132A16.8852,16.8852,0,0,1,526.1308,560.0073Z"
            fill="none" stroke-miterlimit="10" opacity="0.7" stroke="#006cba" />

          {/* Image */}
          <CertificateImage
            imageBuffer={imageBuffer}
            imageExt={imageExt}
          />

          {/* Line */}
          <Line x1="101.2262" y1="480.2753" x2="433.6081" y2="480.2753" fill="none" stroke="#231916" stroke-miterlimit="10" />
          {/* <Line x1="114.3987" y1="440.8177" x2="423.1272" y2="440.8177" fill="none" stroke="#056fb8" stroke-miterlimit="10" />
          <Line x1="114.3987" y1="471.8291" x2="423.1272" y2="471.8291" fill="none" stroke="#056fb8" stroke-miterlimit="10" /> */}

          {/* Message */}
          <CertificateMessage
            sentMessage={sentMessage}
          />
        </G>
      </G>
    </Svg >
  );
};

export default CertificateFrame;