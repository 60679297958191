import React from 'react';
import { TextField } from '@mui/material';

const TextFieldCustom = (props) => {
  let inputProps = {};
  if (props.autoComplete) {
    inputProps = {
      autoComplete: 'new-password',
      form: {
        autoComplete: 'off',
      },
    };
  }

  return (
    <TextField
      type="text"
      fullWidth
      color="success"
      sx={props.sx}

      id={props.id}
      name={props.name}
      placeholder={props.placeholder}
      label={props.label}
      value={props.form.values[props.id] ? props.form.values[props.id] : ""}
      onChange={props.form.handleChange}
      error={props.form.touched[props.id] && props.form.errors[props.id] !== undefined}
      helperText={props.form.touched[props.id] && props.form.errors[props.id]}

      inputProps={inputProps}
    />
  );
};

export {
  TextFieldCustom
};