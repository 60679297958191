import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import partnerApi from '../../api/partner.api';
import ButtonBox from '../../components/common/ButtonBox';
import { HeaderBox } from '../../components/common/HeaderBox';
import StackCol from '../../components/common/StackCol';
import { TableRowTwoText } from '../../components/common/TableRowCustom';
import { routes, routesGen } from '../../configs/routes.config';
import { addBreadcrumb, removeBreadcrumbAtLevel } from '../../redux/features/app.state.slice';
import { formattedDate } from '../../utils/date.utils';
import LANGUAGE from '../../utils/language.util';
import AlertErrorDialog from '../../components/dialog/AlertErrorDialog';

const PartnerDetailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const [partner, setPartner] = useState();
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessageDeleted, setErrorMessageDeleted] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const getPartner = async () => {
      const { response, err } = await partnerApi.getOne(id);

      if (err) toast.error(err.message);
      if (response) setPartner(response);
    };
    getPartner();
  }, [id]);

  useEffect(() => {
    dispatch(addBreadcrumb({
      displayText: id,
      level: 3,
      link: routesGen.partnerDetail(id)
    }));
  }, [dispatch, id]);


  useEffect(() => {
    return () => {
      dispatch(removeBreadcrumbAtLevel({ level: 3 }));
    };
  }, [dispatch]);

  const onClickEdit = async () => {
    navigate(routesGen.partnerUpdate(id));
  };

  const onClickReturn = async () => {
    navigate(-1);
  };

  const onClickConfirmDelete = async () => {
    setIsDeleting(true);
    const { response, err } = await partnerApi.delete(id);

    if (err) {
      setOpenErrorDialog(true);
      setErrorMessageDeleted(err.message);
      setIsDeleting(false);
    };
    if (response) {
      toast.success(LANGUAGE.DELETED_SUCCESS);
      setTimeout(() => {
        setIsDeleting(false);
        navigate(routes.PARTNERS);
      }, 1500);
    };
  };

  return (
    <StackCol>
      <HeaderBox title={LANGUAGE.PARTNER_MANAGEMENT} />
      {partner &&
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRowTwoText
                title={LANGUAGE.SHORT_ID}
                value={partner.short_id}
              />
              <TableRowTwoText
                title={LANGUAGE.COMPANY_NAME}
                value={partner.company_name}
              />
              <TableRowTwoText
                title={LANGUAGE.REPRESENTATIVE_NAME}
                value={partner.first_name + " " + partner.last_name}
              />
              <TableRowTwoText
                title={LANGUAGE.COMPANY_POST_CODE}
                value={partner.postcode}
              />
              <TableRowTwoText
                title={LANGUAGE.PREFECTURE}
                value={partner.prefecture}
              />
              <TableRowTwoText
                title={LANGUAGE.ADDRESS_PREFECTURE_BELOW}
                value={partner.address}
              />
              <TableRowTwoText
                title={LANGUAGE.BUILDING_NAME}
                value={partner.building}
              />
              <TableRowTwoText
                title={LANGUAGE.REPRESENTATIVE_TELEPHONE}
                value={partner.tel}
              />
              <TableRowTwoText
                title={LANGUAGE.REPRESENTATIVE_EMAIL}
                value={partner.email}
              />
              <TableRowTwoText
                title={LANGUAGE.CONTACT_PERSON_NAME}
                value={partner.contact_person_display_name}
              />
              <TableRowTwoText
                title={LANGUAGE.CONTACT_PERSON_TEL}
                value={partner.contact_person_tel}
              />
              <TableRowTwoText
                title={LANGUAGE.CONTACT_PERSON_EMAIL}
                value={partner.contact_person_email}
              />
              <TableRowTwoText
                title={LANGUAGE.BANK_NUMBER}
                value={partner.bank_number}
              />
              <TableRowTwoText
                title={LANGUAGE.CREATED_DATE}
                value={formattedDate(partner.created_date)}
              />
              <TableRowTwoText
                title={LANGUAGE.UPDATED_DATE}
                value={formattedDate(partner.updated_date)}
              />
            </TableBody>
          </Table>
        </TableContainer>
      }
      <ButtonBox
        onClickReturn={onClickReturn}
        onClickEdit={onClickEdit}
        onClickConfirmDelete={onClickConfirmDelete}
        isDeleting={isDeleting}
      />
      <AlertErrorDialog
        openDialog={openErrorDialog}
        setOpenDialog={setOpenErrorDialog}
        title="代理店の削除"
        message={errorMessageDeleted}
      />
    </StackCol>
  );
};

export default PartnerDetailPage;