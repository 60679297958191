import {
  Box, colors
} from "@mui/material";
import React from 'react';
import { Outlet } from "react-router-dom";
import Animate from "../../components/common/Animate";
import Logo from "../common/Logo";

const AuthLayout = () => {
  return (
    <Box
      position="relative"
      height="100vh"
      sx={{ "::-webkit-scrollbar": { display: "none" } }}
    >
      <Box sx={{
        position: "absolute",
        left: 0,
        height: "100%",
        width: "100%",
        transition: "all 1s ease-in-out",
        bgcolor: colors.common.white
      }}>
        <Box sx={{
          flexDirection: "column",
          justifyContent: "space-between",
          opacity: 1,
          transition: "all 0.3s ease-in-out",
          height: "100%",
          "::-webkit-scrollbar": { display: "none" }
        }}>
          {/* logo */}
          <Box sx={{
            textAlign: "center",
            p: 5,
            borderBottom: "1px solid #EEEEEE",
          }}
          >
            <Animate type="fade" delay={0.5}>
              <Logo></Logo>
            </Animate>
          </Box>
          {/* logo */}
          <Outlet />
        </Box>
      </Box>
    </Box >
  );
};

export default AuthLayout;